import React, { useEffect, useState, useMemo } from "react";
import { useMap, Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";
import * as turf from "@turf/turf";
import customClassifyRings from "../MapboxOverrides/MapboxOverrides"; // Import the classifyRings function

const layout_colors = {
  racks: "#33A2FF",
  inverter: "#ee00ff",
  combiner_boxes: "#7FFF00",
  wires: "#33A2FF",
  strings: "#33A2FF",
  roads: "#e0710f",
};

const EditedMBLayout = ({ show_inverters, show_racks }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const show_layout_editor = useSelector((state) => state.sift.ioManager.uiState.show_layout_editor);

  const layout = useSelector((state) => state.sift.ioManager.outputs.layout);
  let memoized_layout = useMemo(() => layout, [layout]);

  const edited_layouts = useSelector((state) => state.inputs?.edited_layouts);
  let memoized_edited_layout = useMemo(() => edited_layouts, [edited_layouts]);

  const selectedResultId = useSelector((state) => state.sift.ioManager?.outputs?.selectedResult?.id || undefined);

  const edited_racks =
    selectedResultId && memoized_edited_layout && memoized_edited_layout[selectedResultId] ? memoized_edited_layout[selectedResultId].inverter_groups[0].combiner_groups[0]["rack_groups"] : undefined;

  const [group_layers, set_group_layers] = useState(undefined);

  useEffect(() => {
    if (show_layout_editor === true) return;
    if (Object.values(memoized_layout).length === 0) return;

    let temp_layouts = {};

    Object.keys(memoized_layout.inverter_groups[0]).forEach((key) => {
      if (key === "racks" && edited_racks && Object.values(edited_racks).length > 0) {
        let rackArr = [];
        Object.values(edited_racks).map((rack) => {
          if (rack.enabled == false) return;
          rackArr.push(rack.geoJson.geometry.coordinates[0]);
        });
        let rack_multipoly = turf.multiPolygon([rackArr], { color: layout_colors["racks"] });
        const updatedRings = customClassifyRings(rack_multipoly.geometry.coordinates);

        temp_layouts[key] = {
          ...rack_multipoly,
          geometry: {
            ...rack_multipoly.geometry,
            coordinates: updatedRings,
          },
          properties: {
            ...rack_multipoly.properties,
            color: rack_multipoly.properties.color ? rack_multipoly.properties.color : layout_colors[key],
          },
        };
      } else {
        const feature = memoized_layout.inverter_groups[0][key];
        const flippedFeature = turf.flip(feature);
        const classifiedRings = customClassifyRings(flippedFeature.geometry.coordinates);

        temp_layouts[key] = {
          ...flippedFeature,
          geometry: {
            ...flippedFeature.geometry,
            coordinates: classifiedRings,
          },
          properties: {
            ...feature.properties,
            color: feature.properties.color ? feature.properties.color : layout_colors[key],
          },
        };
      }
    });

    set_group_layers(temp_layouts);
  }, [show_layout_editor]);

  //   useEffect(() => {
  //     if (!map || !group_layers) return;

  //     Object.keys(group_layers).forEach((key) => {
  //       const sourceId = `polygon-source-${key}`;
  //       const layerId = `polygon-layer-${key}`;

  //       // Check if the layer already exists and remove it
  //       if (map.getLayer(layerId)) {
  //         map.removeLayer(layerId);
  //       }

  //       // Check if the source exists and remove it
  //       if (map.getSource(sourceId)) {
  //         map.removeSource(sourceId);
  //       }

  //       map.addSource(sourceId, {
  //         type: "geojson",
  //         data: group_layers[key],
  //       });

  //       map.addLayer({
  //         id: layerId,
  //         type: "line",
  //         source: sourceId,
  //         paint: {
  //           "line-color": group_layers[key].properties.color,
  //           "line-width": 1,
  //         },
  //       });
  //     });
  //   }, [group_layers]);

  if (!group_layers || Object.values(layout).length == 0) return null;

  return (
    <>
      {Object.values(group_layers).length > 0 &&
        Object.keys(group_layers).map((key) => {
          if (key === "inverters" && !show_inverters) return;
          if (key === "racks" && !show_racks) return;
          return (
            <Source key={key} id={key} type="geojson" data={group_layers[key]}>
              <Layer
                type="line"
                paint={{
                  "line-color": group_layers[key].properties.color,
                  "line-width": 1,
                }}
              />
            </Source>
          );
        })}
    </>
  );
};

export { EditedMBLayout };

import React, { useState, useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useSelector, dispatch, useDispatch } from "react-redux";
import { getCorners, flipGeoJson } from "../ShadeHelpers/Shade.editor";

import { canopyActions, usePrevious } from "../../Redux";

const TreeLineEventLayer = ({ id, interactive = true }) => {
  const map = useMap();
  const dispatch = useDispatch();

  const treeLine = useSelector((state) => state.canopy.shade_objects[id]);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const prev_selected_shade_object_id = usePrevious(selected_shade_object_id);

  const edit_shade = useSelector((state) => state.canopy.edit_shade);
  const move_shade = useSelector((state) => state.canopy.move_shade);
  const rotate_shade = useSelector((state) => state.canopy.rotate_shade);

  const prev_edit_shade = usePrevious(edit_shade);
  const prev_move_shade = usePrevious(move_shade);
  const prev_rotate_shade = usePrevious(rotate_shade);

  const [editing, set_editing] = useState(false);

  useEffect(() => {
    if (!prev_edit_shade && edit_shade) {
      edit_shade_object();
    }
  }, [edit_shade]);

  useEffect(() => {
    if (!prev_move_shade && move_shade) {
      move_shade_object();
    }
  }, [move_shade]);

  useEffect(() => {
    if (!prev_rotate_shade && rotate_shade) {
      rotate_shade_object();
    }
  }, [rotate_shade]);

  const edit_shade_object = () => {
    if (selected_shade_object_id != id) return;
    let layer = getLayer(selected_shade_object_id);
    if (!layer) return;
    layer.pm.enable();
  };

  const move_shade_object = () => {
    if (selected_shade_object_id != id) return;
    let layer = getLayer(selected_shade_object_id);
    if (!layer) return;
    layer.pm.enableLayerDrag();
  };

  const rotate_shade_object = () => {
    if (selected_shade_object_id != id) return;
    let layer = getLayer(selected_shade_object_id);
    if (!layer) return;
    layer.pm.enableRotate();
  };

  // useMapEvents({
  //   edit_shade_object: (e) => {
  //     let layer = getLayer(e.id);
  //     if (!layer) return;
  //     layer.pm.enable();
  //   },
  //   rotate_shade_object: (e) => {
  //     let layer = getLayer(e.id);
  //     if (!layer) return;
  //     layer.pm.enableRotate();
  //   },
  //   move_shade_object: (e) => {
  //     let layer = getLayer(e.id);
  //     if (!layer) return;
  //     layer.pm.enableLayerDrag();
  //   },
  // });

  useEffect(() => {
    if ((selected_shade_object_id == undefined && prev_selected_shade_object_id == treeLine.id) || (prev_selected_shade_object_id == treeLine.id && selected_shade_object_id != treeLine.id)) {
      map.eachLayer((layer) => layer.options.type == "treeLine" && layer.options.id == treeLine.id && layer.pm.disable());
    }

    if (selected_shade_object_id == treeLine.id && !rotate_shade && !move_shade) {
      map.eachLayer((layer) => layer.options.type == "treeLine" && layer.options.id == treeLine.id && layer.pm.enable());
    }
  }, [selected_shade_object_id, prev_selected_shade_object_id]);

  useEffect(() => {
    if (!treeLine) return;
    // console.log("treeline", treeLine);

    map.eachLayer((layer) => {
      if (layer.options && layer.options.id == treeLine.id) {
        layer.remove();
      }
    });

    if (treeLine?.checked === false) return;

    let treeLine_obj = new L.polyline(treeLine.geoJson.geometry.coordinates, { ...treeLine, interactive });

    treeLine_obj.addTo(map);

    if (selected_shade_object_id == treeLine.id) {
      treeLine_obj.pm.enable();
    }

    treeLine_obj.setStyle({
      stroke: true,
      color: "var(--primary-brand-color)",
      opacity: 1.0,
      weight: 3,
      fillColor: "var(--primary-brand-color)",
    });

    // treeLine_obj.on("click", (e) => {
    //   if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    //   if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
    // });

    treeLine_obj.on("click", (e) => {
      if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
      if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
      if (e.target.pm.enabled()) {
        e.target.pm.disable();
        set_editing(false);
        if (e.target.options.id == selected_shade_object_id) {
          dispatch(canopyActions.updateShadeObject("selected_shade_object_id", undefined));
        }
        return;
      } else {
        e.target.pm.enable();
        dispatch(canopyActions.updateShadeObject("selected_shade_object_id", e.target.options.id));
      }
    });

    treeLine_obj.on("pm:edit", (e) => {
      if (!e) return;
      update_treeLine(e.layer);
      e.layer.remove();
    });
    return () => {
      treeLine_obj.off("click");
      treeLine_obj.off("pm:edit");
    };
  }, [treeLine, treeLine.checked]);

  const update_treeLine = (layer) => {
    set_editing(true);
    let edited_treeLine = { ...treeLine };
    let new_geoJson = layer.toGeoJSON();
    edited_treeLine.geoJson = flipGeoJson(new_geoJson);
    edited_treeLine.center = layer.getBounds().getCenter();
    edited_treeLine.corners = getCorners(layer);
    edited_treeLine.rotation = 0;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_treeLine));
  };

  const getLayer = (id) => {
    let temp_layer = false;
    map.eachLayer((layer) => {
      if (layer.options?.type == "treeLine" && layer.options.id == id) {
        temp_layer = layer;
        return;
      }
    });
    return temp_layer;
  };

  return <></>;
};

export { TreeLineEventLayer };

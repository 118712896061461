import React, { useState, useEffect, useRef } from "react";
//import styled from "styled-components";
import { matchSorter } from "match-sorter";

// components
import { EditableCell, EditableRow } from "./EditableTable";
import { getSiteCenter, UserActions, usePrevious } from "../Redux";
import { siftService } from "../Redux/_services";
import { ProjectManagerWrap } from "./styles";

import { Button, ButtonGroup, TextArea, Modal } from "../BaseComponents";
// antd
import { Input, Menu, Dropdown, Table, Popconfirm, Checkbox, Spin, Tooltip, Empty } from "antd";

import {
  CloudOutlined,
  SaveOutlined,
  PlusOutlined,
  LoadingOutlined,
  EllipsisOutlined,
  FolderOpenOutlined,
  BookOutlined,
  ExclamationCircleFilled,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  UserAddOutlined,
  UnlockOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ProjectOutlined, RightOutlined } from "@ant-design/icons";
const small_loading = <LoadingOutlined style={{ fontSize: "15px", marginRight: "5px" }} spin />;

//redux
import { useSelector, useDispatch } from "react-redux";
import { canopyActions, inputsActions, projectActions, siftActions } from "../Redux";
//import { isAuthenticated } from "../Redux";
import { ValidateEmail } from "./helper";
import { LockedByYou, LockedBySomeone, NotLocked, collab_columns, notes_columns } from "./CollabAssets";
import { set } from "lodash";
//import { check } from "../../assets/images";

const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

//let shareModal = undefined;

const format_date_ts = (timestamp) => {
  let date = new Date(timestamp * 1000);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  let format_d = `${strTime} - ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return format_d.toString();
};

const CheckInNoteTextArea = ({ handleCheckInText, loading, current_collab_note_ref, check_in_collab }) => {
  return (
    <TextArea
      id="check_in_note"
      rows={4}
      width="unset"
      value={current_collab_note_ref.current}
      disabled={loading}
      placeholder="Optional note for Check In"
      onChange={handleCheckInText}
      onPressEnter={() => check_in_collab(true)}
      style={{ width: "300px", marginRight: "10px" }}
    />
  );
};

const TempEmailInput = ({ loading, temp_collab_email_ref, share_project_with_email }) => {
  const can_share = useSelector((state) => state.user.product_plan_paid > 1);

  const [_email, set_email] = useState("");

  useEffect(() => {
    temp_collab_email_ref.current = _email;
  }, [_email]);

  return (
    <Input
      id="share_with"
      value={_email}
      disabled={!can_share || loading}
      placeholder={can_share ? "Enter an email." : "Starter accounts cannot share projects."}
      onChange={(e) => set_email(e.target.value)}
      onPressEnter={() => share_project_with_email()}
      style={{ width: "300px", marginRight: "10px" }}
    />
  );
};

const NotifyCheckbox = ({ handleCheckbox, collab_notify_users_ref }) => {
  return (
    <Checkbox style={{ marginLeft: 5 }} checked={collab_notify_users_ref} onChange={handleCheckbox}>
      Notify users of Check In
    </Checkbox>
  );
};

const ProjectManager = () => {
  const inputs = useSelector((state) => state.inputs);
  const projects = useSelector((state) => state.projects);
  // console.log('PROIJECT MANAGER', projects)
  const tutorial = useSelector((state) => state.tutorial);
  const can_share = useSelector((state) => state.user.product_plan_paid > 1);
  const plan_restrictions = useSelector((state) => state.user.plan_restrictions);
  const generated = useSelector((state) => state.sift.ioManager.outputs.runState.generated);
  const running = useSelector((state) => state.sift.ioManager.outputs.runState.running);
  const pm_modal_visible = useSelector((state) => state.inputs.uiState.pm_modal_visible);
  const canopy = useSelector((state) => state.canopy);
  const running_canopy = useSelector((state) => state.canopy.running_canopy);

  const en_target_inputs = useSelector((state) => state.user.en_target_inputs);
  const [loading, set_loading] = useState(false);

  const dispatch = useDispatch();

  const [modal_visible, set_modal_visible] = useState({ visible: false, type: "load" });
  const [collab_notify_users, set_collab_notify_users] = useState(true);
  const [collab_visible, set_collab_modal_visible] = useState({ visible: false, project_id: undefined });
  const [temp_collab_email, set_temp_collab_email] = useState("");
  const [current_collab_note, set_current_collab_note] = useState("");
  const [temp_project_name, set_temp_project_name] = useState("");
  const [selected_project_id, set_selected_project_id] = useState(undefined);
  const [collab_error, set_collab_error] = useState(undefined);
  const [project_sorted_info, set_project_sorted_info] = useState({ columnKey: "edit_dt", field: "edit_dt", order: "descend" });
  //const [collab_sortedInfo, set_collab_sortedInfo] = useState({ columnKey: "edit_dt", field: "edit_dt", order: "descend" });

  const [save_as_modal, set_save_as_modal] = useState({ visible: false, type: "load", temp_project_name: "", temp_variant_name: "" });
  const [table_key, set_table_key] = useState(1);

  const [filter_text, set_filter_text] = useState("");
  const [filtered_projects, set_filtered_projects] = useState(undefined);

  const cannot_save_collab = inputs.project_id && projects.local[inputs.project_id]?.collab_on == 1 && projects.local[inputs.project_id]?.locked && !projects.local[inputs.project_id]?.locked_by_me;

  const can_checkin = inputs.project_id && projects.local[inputs.project_id]?.collab_on == 1 && projects.local[inputs.project_id]?.locked_by_me;
  const can_checkout = inputs.project_id && projects.local[inputs.project_id]?.collab_on == 1 && !projects.local[inputs.project_id]?.locked;

  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";
  const location_pathname = window.location.pathname;
  const canopy_results_generated = useSelector((state) => state.canopy.canopy_results_generated);
  const collab_on = projects.local[inputs.project_id]?.collab_on;
  const locked_by_me = projects.local[inputs.project_id]?.locked_by_me;
  const prev_locked_by_me = usePrevious(locked_by_me);

  const project_id_ref = useRef();
  const temp_collab_email_ref = useRef();
  const current_collab_note_ref = useRef();
  const collab_notify_users_ref = useRef();

  const collab_message = (using_canopy) => {
    if (using_canopy) {
      return (
        canopy.project_id &&
        projects.local[canopy.project_id]?.collab_on === 1 &&
        (projects.local[canopy.project_id]?.locked_by_me ? LockedByYou : projects.local[canopy.project_id]?.locked ? LockedBySomeone(projects.local[canopy.project_id]?.locked_by_email) : NotLocked)
      );
    } else {
      return (
        inputs.project_id &&
        projects.local[inputs.project_id]?.collab_on === 1 &&
        (projects.local[inputs.project_id]?.locked_by_me ? LockedByYou : projects.local[inputs.project_id]?.locked ? LockedBySomeone(projects.local[inputs.project_id]?.locked_by_email) : NotLocked)
      );
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     manage_modal_zIndex(modal_visible);
  //   }, 350);
  // }, [modal_visible.visible]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     manage_modal_zIndex(collab_visible);
  //   }, 350);
  // }, [collab_visible.visible]);

  // TODO_NICK : REIMPLEMENT THIS LATER
  // useEffect(() => {
  //   filter_the_projects();
  //   set_temp_project_name("");
  //   if (collab_visible.visible && collab_visible.project_id === undefined) {
  //     refresh_project();
  //   }
  // }, [projects.local]);

  // useEffect(() => {
  //   filter_the_projects();
  // }, [location_pathname]);

  const filter_the_projects = () => {
    // let project_type_to_filter = using_canopy ? 3 : 1;
    // let temp_filtered_projects = Object.values(projects.local).filter((project) => project.project_type == project_type_to_filter || project.project_type == 4);
    let temp_filtered_projects = Object.values(projects.local).filter((project) => {
      if (using_canopy) {
        return project.project_type === 3 || project.project_type === 4 || project.active === 2 || project.active === 3;
      } else {
        return project.project_type === 1 || project.project_type === 2 || (!using_canopy && project.active === 1) || project.project_type === 4;
      }
    });
    // console.log("temp_filtered_projects", temp_filtered_projects);

    set_filtered_projects(temp_filtered_projects);
  };

  useEffect(() => {
    if (collab_on !== undefined && prev_locked_by_me && !locked_by_me) {
      new_project();
    }
  }, [locked_by_me, prev_locked_by_me, collab_on]);

  // useEffect(() => {
  //   console.log("ran rand");
  //   // This is kind of a wonky work around, but it's used to cause the project table to re-render when toggling it's visibility.
  //   // If this is disabled it causes the table sorters at the top of the Project table to be invisible when toggling the modal on and off on smaller screens/browser windows.
  //   if (modal_visible.visible) {
  //     set_table_key(2);
  //   } else {
  //     set_table_key(1);
  //   }
  // }, [modal_visible.visible]);

  // useEffect(() => {
  //   console.log("changed", collab_visible);
  //   console.log("projects.local[collab_visible.project_id]", projects.local[collab_visible.project_id]);
  // }, [collab_visible]);

  // useEffect(() => {
  //   console.log("test", !projects.local[collab_visible.project_id]);
  // }, [projects.local[collab_visible.project_id]]);

  // this effect runs whenever the filter_text changes. Used to filterting projects
  // useEffect(() => {
  //   filter_projects();
  // }, [filter_text]);

  // const filter_projects = () => {
  //   if (filter_text === "") {
  //     set_filtered_projects(Object.values(projects.local));
  //     return;
  //   }
  //   let filtered_projects =
  //     matchSorter(Object.values(projects.local), filter_text, {
  //       keys: ["project_name"],
  //     }) || Object.values(projects.local);
  //   set_project_sorted_info({ columnKey: "edit_dt", field: "edit_dt", order: "descend" });
  //   set_filtered_projects(filtered_projects);
  // };

  // const sort_table = (pagination, filters, sorter) => {
  //   let _sorter = sorter;
  //   // if (!sorter.order) {
  //   //   // interrupt the default functionality
  //   //   _sorter = project_sorted_info;
  //   //   _sorter.order = "ascend";
  //   // }
  //   // if(_sorter && _sorter.column && !_sorter.column.sortOrder){
  //   //   _sorter.column.sortOrder = sorter.order === 'ascend' ? 'descend' : 'ascend';
  //   // }
  //   _sorter.columnKey = _sorter.field;
  //   set_project_sorted_info(_sorter);
  // };

  // const updateProject_location_object = () => {};

  const get_project_inputs = (save_as = false) => {
    let save_project;
    if (internal_sift && using_canopy) {
      save_project = {
        project_id: canopy.project_id,
        rectangles: canopy.canopies,
        shade_objects: canopy.shade_objects,
        en_shade_impact: canopy?.en_shade_impact,
        images: canopy.images,
        project_name: canopy.project_name,
        module: inputs.mod_id,
        active: 2,
        project_type: 3,
        map_center: Object.values(canopy.canopies).length > 0 ? getSiteCenter(canopy.canopies) : inputs.map_center,
        uiState: { pm_modal_visible: false },
        locked_by_email: !save_as && projects.local[inputs.project_id]?.locked_by_email ? projects.local[inputs.project_id]?.locked_by_email : undefined,
        locked: !save_as && projects.local[inputs.project_id]?.locked ? projects.local[inputs.project_id]?.locked : undefined,
        locked_by_me: !save_as && projects.local[inputs.project_id]?.locked_by_me ? projects.local[inputs.project_id]?.locked_by_me : undefined,
        collab_on: !save_as && projects.local[inputs.project_id]?.collab_on ? projects.local[inputs.project_id]?.collab_on : undefined,
        creator: !save_as && projects.local[inputs.project_id]?.creator ? projects.local[inputs.project_id]?.creator : undefined,
        user_list: !save_as && projects.local[inputs.project_id]?.user_list ? projects.local[inputs.project_id]?.user_list : undefined,
        notes: !save_as && projects.local[inputs.project_id]?.notes ? projects.local[inputs.project_id]?.notes : undefined,
      };
    } else if (internal_sift) {
      save_project = {
        ...inputs,
        edited_layouts: {},
        project_id: inputs.project_id,
        project_name: inputs.project_name,
        project_type: 1,
        active: 1,
        images: canopy.images,
        en_target_inputs: en_target_inputs,
        site_features: inputs.site_features,
        shade_objects: canopy.shade_objects,
        en_shade_impact: canopy?.en_shade_impact,
        racking_errors: undefined,
        ground_saved_state: undefined,
        canopy_saved_state: undefined,
        locked_by_email: !save_as && projects.local[inputs.project_id]?.locked_by_email ? projects.local[inputs.project_id]?.locked_by_email : undefined,
        locked: !save_as && projects.local[inputs.project_id]?.locked ? projects.local[inputs.project_id]?.locked : undefined,
        locked_by_me: !save_as && projects.local[inputs.project_id]?.locked_by_me ? projects.local[inputs.project_id]?.locked_by_me : undefined,
        collab_on: !save_as && projects.local[inputs.project_id]?.collab_on ? projects.local[inputs.project_id]?.collab_on : undefined,
        creator: !save_as && projects.local[inputs.project_id]?.creator ? projects.local[inputs.project_id]?.creator : undefined,
        user_list: !save_as && projects.local[inputs.project_id]?.user_list ? projects.local[inputs.project_id]?.user_list : undefined,
        notes: !save_as && projects.local[inputs.project_id]?.notes ? projects.local[inputs.project_id]?.notes : undefined,
      };
    } else {
      save_project = {
        ...inputs,
        edited_layouts: {},
        project_id: inputs.project_id,
        project_name: inputs.project_name,
        project_type: 1,
        active: 1,
        images: canopy.images,
        en_target_inputs: en_target_inputs,
        site_features: inputs.site_features,
        shade_objects: canopy.shade_objects,
        en_shade_impact: canopy?.en_shade_impact,
        racking_errors: undefined,
        ground_saved_state: undefined,
        canopy_saved_state: undefined,
        locked_by_email: !save_as && projects.local[inputs.project_id]?.locked_by_email ? projects.local[inputs.project_id]?.locked_by_email : undefined,
        locked: !save_as && projects.local[inputs.project_id]?.locked ? projects.local[inputs.project_id]?.locked : undefined,
        locked_by_me: !save_as && projects.local[inputs.project_id]?.locked_by_me ? projects.local[inputs.project_id]?.locked_by_me : undefined,
        collab_on: !save_as && projects.local[inputs.project_id]?.collab_on ? projects.local[inputs.project_id]?.collab_on : undefined,
        creator: !save_as && projects.local[inputs.project_id]?.creator ? projects.local[inputs.project_id]?.creator : undefined,
        user_list: !save_as && projects.local[inputs.project_id]?.user_list ? projects.local[inputs.project_id]?.user_list : undefined,
        notes: !save_as && projects.local[inputs.project_id]?.notes ? projects.local[inputs.project_id]?.notes : undefined,
      };
    }

    // ensure that the pm_modal_visible isn't saved as true when the save modal is open.
    // If it is saved as true, it causes issues with editing
    // polygons in the map right after loading a project as several
    // functions in the map check to see if a modal is open, i.e search, hotkeys etc.
    save_project.uiState.pm_modal_visible = false;
    // if (!internal_sift && !using_canopy) {
    // }
    return save_project;
  };

  const new_project = () => {
    // new project confirmed
    if (internal_sift && using_canopy) {
      dispatch(canopyActions.new_project());
    } else {
      dispatch(inputsActions.reset_inputs_to_default());
      dispatch(siftActions.newProject());
      set_selected_project_id(undefined);
      if (Object.values(canopy.shade_objects).length) dispatch(canopyActions.updateShadeObject("delete_all_shade_objects"));
      // setTimeout(() => {
      //   dispatch(UserActions.updateUserValue("revert_to_initial_en_target_inputs"));
      // }, 1000);
    }
  };

  const save_project = async () => {
    let save_project_inputs = get_project_inputs();

    // console.log("save_project_inputs", save_project_inputs.shade_objects);
    // console.log("projects.local[inputs.project_id]", projects.local[inputs.project_id]);

    // check to see if there is a location object
    // if not, we need to get the lat and lng
    // if  using canopy, the lat lng will be in the "map_center" object else it will be latitude/longitude in the save_project_inputs object.
    // once we have the lat and lng, we can grab the location data and updates the inputs
    // save the project.

    if (!save_project_inputs.location_obj) {
      let lat, lng;
      lat = using_canopy ? save_project_inputs.map_center[0] : save_project_inputs.latitude;
      lng = using_canopy ? save_project_inputs.map_center[1] : save_project_inputs.longitude;

      if (lat && lng) {
        let loc_obj_res = await siftService.getElevation([lat, lng]);
        let loc_obj;

        if (loc_obj_res.data.loc.results.length > 0) {
          loc_obj = loc_obj_res.data.loc.results[0]?.address_components
            ? {
                city: loc_obj_res.data.loc.results[0]?.address_components[0]?.long_name,
                state: loc_obj_res.data.loc.results[0]?.address_components[1]?.long_name,
                country: loc_obj_res.data.loc.results[0]?.address_components[2]?.long_name,
              }
            : undefined;
        }

        save_project_inputs.location_obj = loc_obj;
      }
    }
    console.log(inputs);
    if ((using_canopy && canopy.project_id) || !_.isUndefined(inputs.project_id)) {
      // there is a project id, normal save

      dispatch(projectActions.update_project(save_project_inputs));
      open_modal(false, "save");
    } else if (modal_visible.visible) {
      // modal has already been opened
      if (!_.isEmpty(temp_project_name)) {
        // name has been entered
        save_project_inputs.project_name = temp_project_name;

        using_canopy ? dispatch(canopyActions.updateCanopyInputs("project_name", temp_project_name)) : dispatch(inputsActions.update_input("project_name", temp_project_name));
        dispatch(projectActions.update_project(save_project_inputs));

        open_modal(false, "save");
        set_selected_project_id(undefined);
      } else {
        console.log("error -- no name has been entered...");
      }
    } else {
      // new project, never been saved before so we need a name first
      // open_modal(true, "save");
      onOpenModal("saveasproject");
    }
  };

  const onOpenModal = (_type) => {
    // dispatch(inputsActions.update_ui_state("disable_hotkeys", true))
    set_save_as_modal({ visible: true, type: _type, temp_project_name: "", temp_variant_name: "" });
  };
  const onCloseModal = () => {
    // dispatch(inputsActions.update_ui_state("disable_hotkeys", false))
    set_save_as_modal({ visible: false, type: "", temp_project_name: "", temp_variant_name: "" });
  };
  const onUpdateProjectName = (e) => {
    set_save_as_modal({ ...save_as_modal, temp_project_name: e.target.value });
  };
  const onUpdateVariantName = (e) => {
    set_save_as_modal({ ...save_as_modal, temp_variant_name: e.target.value });
  };
  const save_as_ = () => {
    if (save_as_modal.type == "saveasvariant" && save_as_modal.temp_variant_name.length == 0) {
      return;
    }
    if (save_as_modal.type == "saveasproject" && save_as_modal.temp_variant_name.length == 0 && save_as_modal.temp_project_name.length == 0) {
      return;
    }
    // set_loading(true);
    console.log("save_as_", save_as_modal.type, save_as_modal.temp_project_name);
    // set_save_as_modal({ visible: true, type: "saveas" });

    // let save_project_inputs = get_project_inputs();

    let _inputs = {
      ...inputs,
      shade_objects: canopy?.shade_objects,
      en_shade_impact: canopy?.en_shade_impact,
      rectangles: using_canopy ? canopy?.canopies : null,
      images: canopy?.images ? canopy?.images : null,
      project_type: using_canopy ? 3 : 1,
      module: using_canopy ? inputs.mod_id : undefined,
      active: using_canopy ? 2 : 1,
      map_center: using_canopy && Object.values(canopy.canopies).length > 0 ? getSiteCenter(canopy.canopies) : inputs.map_center,
    };

    // name has been entered
    if (save_as_modal.type == "saveasvariant") {
      _inputs.variant_id = undefined;
    }
    if (save_as_modal.type == "saveasproject") {
      _inputs.project_id = undefined;
      _inputs.variant_id = undefined;
      _inputs.project_name = save_as_modal.temp_project_name;
      // _inputs.new_project = true;
      dispatch(inputsActions.update_input("project_name", save_as_modal.temp_project_name));
    }
    _inputs.variant_name = save_as_modal.temp_variant_name;
    dispatch(inputsActions.update_input("variant_name", save_as_modal.temp_variant_name));
    dispatch(projectActions.update_project(_inputs, save_as_modal.type));
    // open_modal(false, "saveas");
    // set_selected_project_id(undefined);
    // set_loading(false);
    onCloseModal();
  };
  const save_as_project = () => {
    let save_as_project_inputs = get_project_inputs(true);
    if (modal_visible.visible || collab_visible.visible) {
      // modal has already been opened
      if (!_.isEmpty(temp_project_name)) {
        // name has been entered
        save_as_project_inputs.project_id = undefined;
        save_as_project_inputs.project_name = temp_project_name;
        dispatch(inputsActions.update_input("project_name", temp_project_name));
        dispatch(projectActions.update_project(save_as_project_inputs));
        open_modal(false, "saveas");
        set_selected_project_id(undefined);
      } else {
        console.log("error -- no name has been entered...");
      }
    } else {
      open_modal(true, "saveas");
    }
  };

  const delete_project = (id = undefined) => {
    let delete_project_inputs = projects.local[id];
    delete_project_inputs.active = 0;
    delete_project_inputs.project_id = id;
    // console.log("delete_project_inputs", delete_project_inputs);
    new_project();
    dispatch(projectActions.update_project(delete_project_inputs, "delete_proj"));
    set_selected_project_id(undefined);
  };

  const share_project_with_email = () => {
    if (!ValidateEmail(temp_collab_email_ref.current)) {
      set_collab_error("You have entered an invalid email address!");
      return;
    }

    let id = collab_visible.project_id || inputs.project_id;

    if (temp_collab_email_ref.current.length > 0 && (collab_visible.project_id || inputs.project_id)) {
      let share_inputs;
      let userList = id && projects.local[id].user_list;
      // if (userList.length == 0)

      if (collab_visible.project_id === inputs.project_id) {
        share_inputs = get_project_inputs();
      } else {
        share_inputs = "in_database";
      }

      let payload = {
        type: "share",
        project_id: id,
        email: temp_collab_email_ref.current,
        inputs: share_inputs,
        project_type: using_canopy ? 3 : 1,
      };
      // console.log("share payload", payload);
      dispatch(projectActions.share_project(payload, userList));

      set_temp_collab_email("");
      temp_collab_email_ref.current = undefined;
      set_collab_error(undefined);
      set_collab_modal_visible({ visible: true, project_id: id });
    } else {
      set_collab_error("Enter an email to share");
    }
  };

  const check_in_collab = (checkin = false, id = undefined, do_save = true) => {
    // only time id is defined is when force check in is pressed
    let collab_id = id || collab_visible.project_id;
    let userList = collab_id && projects.local[collab_id].user_list;
    // if checking in a project that isn't loaded
    // don't send anything since we don't have to save, just checking in with a note
    let project_inputs;
    if (checkin && collab_id === inputs.project_id) {
      project_inputs = get_project_inputs();
      // project_inputs = {
      //   ...inputs,
      //   project_id: inputs.project_id,
      //   project_name: inputs.project_name,
      //   project_type: 1,
      //   active: 1,
      // };

      // console.log("project_inputs", project_inputs);
    }

    let payload = {
      type: "checkinout",
      checkin: checkin,
      project_id: collab_id,
      project: project_inputs,
      project_name: projects.local[collab_id].project_name,
      note: current_collab_note,
      notify: collab_notify_users,
      user_list: userList,
    };

    if (do_save && collab_id === inputs.project_id) {
      // only called when we are checking in or checking out the CURRENT project and needs to be saved
      dispatch(projectActions.check_in_collab(payload));
    } else {
      // Called when checking in/out project from load menu (not currently opened)
      dispatch(projectActions.force_check_in(payload));
    }

    // if collab_id and currentProjectId don't match
    // 			this means the user has a project open, and launched the collaborate window
    // 			from the project table from a DIFFERENT project.
    // 		Doing this action will not load the checked-in project, but if checking out
    // 			the project that is open, it will automatically reload the project..
    // 		Not sure if we should load the project you are checking out if it's different..

    if (!checkin && collab_id !== inputs.project_id && do_save) {
      load_project(collab_id);
      set_collab_modal_visible({ visible: false, project_id: collab_id });
    } else {
      if (collab_visible.visible) {
        set_collab_modal_visible({ visible: true, project_id: collab_id });
      }
    }

    set_current_collab_note("");
    set_collab_notify_users(false);
  };

  const refresh_project = () => {
    // console.log(this.props.collab_projectId);
    if (collab_visible.project_id && projects.local[collab_visible.project_id].collab_on) {
      dispatch(projectActions.refresh_project(collab_visible.project_id));
    }
  };

  const menu_button_pressed = (event) => {
    // console.log("menu pressed", key);
    let key = event.key;
    if (key == "saveasproject") {
      onOpenModal(key);
    } else if (key === "saveasvariant") {
      onOpenModal(key);
    } else if (key === "checkin") {
      check_in_collab(true, inputs.project_id, true);
    } else if (key === "checkout") {
      check_in_collab(false, inputs.project_id, true);
    }
  };

  const open_modal = (visible, type) => {
    // set_modal_visible({ visible: visible, type: type });
    dispatch(inputsActions.update_ui_state("pm_modal_visible", visible));
  };
  const open_datamanager = () => {
    dispatch(inputsActions.update_ui_state("pm_modal_visible", !pm_modal_visible));
  };

  const open_collab = (toggle = true, project_id = undefined) => {
    // console.log(toggle, 'opening collab', project_id)
    if (!toggle) {
      // make sure this modal gets toggles off too.
      // If you navigate to the collab menu using the small links next to project names and then close the
      // modal by clicking outside of the div, pm_modal_visible never gets toggled off, which then disabled hotkeys in the map
      dispatch(inputsActions.update_ui_state("pm_modal_visible", false));
    }

    // if (project_id) {
    //   console.log(projects.local[project_id]);
    // }

    if (toggle) {
      set_modal_visible({ visible: false, type: "" });
    }

    dispatch(inputsActions.update_ui_state("collab_modal_visible", { visible: toggle, project_id: project_id }));
    set_collab_modal_visible({ visible: toggle, project_id: project_id });
  };

  useEffect(() => {
    console.log("collab_visible", inputs.uiState.collab_modal_visible);
    if (!_.isUndefined(inputs.uiState.collab_modal_visible) && inputs.uiState.collab_modal_visible.visible) {
      dispatch(inputsActions.update_ui_state("pm_modal_visible", false));
      set_collab_modal_visible(inputs.uiState.collab_modal_visible);
    }
  }, [inputs.uiState.collab_modal_visible]);

  const load_project = (id = undefined) => {
    if (generated) {
      new_project();
    }
    if (using_canopy) {
      new_project();
    } else {
      if (Object.values(canopy.shade_objects).length) dispatch(canopyActions.updateShadeObject("delete_all_shade_objects"));
    }

    // either double clicked from table
    // or a load button has been pressed after selecting a project
    let project_id_to_load = id || selected_project_id;

    if (project_id_to_load) {
      let project_to_load = projects.local[project_id_to_load];
      dispatch(projectActions.load_project(project_to_load));
      open_modal(false, modal_visible.type);

      // if project is not locked, meaning it's not out by someone else, then check it out.
      if (project_id_to_load.collab_on && !project_to_load.locked) {
        check_in_collab(false, project_id_to_load, false);
      }
    }
  };

  const not_usable = !projects.loaded;

  const items = [
    {
      label: "Save As Project",
      key: "saveasproject",
      icon: <SaveOutlined />,
    },
    {
      label: "Save As Variant",
      key: "saveasvariant",
      icon: <SaveOutlined />,
    },
    {
      label: "Check In",
      key: "checkin",
      icon: <CloudUploadOutlined style={{ fontSize: "19px" }} />,
      disabled: !can_checkin,
    },
    {
      label: "Check Out",
      key: "checkout",
      icon: <CloudDownloadOutlined style={{ fontSize: "19px" }} />,
      disabled: !can_checkout,
    },
  ];
  const menuProps = {
    items,
    onClick: menu_button_pressed,
  };
  // const PMDropDownMenu = () => (
  //   <Menu onClick={({ item, key, keyPath, domEvent }) => menu_button_pressed(key)}>
  //     <Menu.Item disabled={not_usable} key="saveas">
  //       <SaveOutlined />
  //       Save As
  //     </Menu.Item>
  //     <Menu.Item disabled={!can_checkin} key="checkin">
  //       <CloudUploadOutlined style={{ fontSize: "19px" }} />
  //       <span style={{ display: "inline-block" }}>Check In</span>
  //     </Menu.Item>
  //     <Menu.Item disabled={!can_checkout} key="checkout">
  //       <CloudDownloadOutlined style={{ fontSize: "19px" }} />
  //       <span style={{ display: "inline-block" }}>Check Out</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  // const loadable = (Object.values(carport.projectManager.localProjects).length == 0 && Object.values(portal.Projects).length == 0) || !isAuthenticated;
  // TODO: Since Portal is hiddein behind a login screen, this isn't reall required till SIFT update

  // console.log(projects.local[inputs.project_id])

  const OnSelectProjectRow = (project_id, load_the_project = false) => {
    project_id_ref.current = project_id;
    if (load_the_project) {
      load_project(project_id_ref.current);
    } else {
      project_id_ref.current = project_id;
      // set_selected_project_id(project_id_ref.current);
    }
  };

  const handleCheckInText = (event) => {
    current_collab_note_ref.current = event.target.value;
  };

  const handleCheckbox = (event) => {
    collab_notify_users_ref.current = event.target.checked;
  };

  return (
    <ProjectManagerWrap pm_modal_visible={pm_modal_visible}>
      <>
        <section
          className="project-manager-buttons"
          id="project-manager-tip"
          style={{ textAlign: "center", border: tutorial.visibleTips.project_manager ? "2px solid var(--primary-brand-color)" : "2px solid transparent" }}
        >
          <section className="pm-buttons">
            <Popconfirm title="Start a new project?" onConfirm={() => new_project()}>
              <Button label="New" icon="PlusOutlined" disabled={using_canopy ? running_canopy : projects.loading || running} style={{ marginRight: "5px" }} size="small" type="ghost" />
            </Popconfirm>
            <ButtonGroup>
              <Tooltip
                title={
                  cannot_save_collab ? (
                    <span>
                      <ExclamationCircleFilled style={{ color: "#faad14", marginRight: "5px" }} /> Project is in a Read Only state. Check Out before editing and to save changes.
                    </span>
                  ) : null
                }
              >
                <Button label="Save" icon="SaveOutlined" type="ghost" loading={projects.saving} disabled={not_usable || cannot_save_collab} id="save" onClick={() => save_project()} size="small" />
              </Tooltip>

              <Dropdown
                trigger={["click"]}
                //overlay={<PMDropDownMenu />}
                menu={menuProps}
                placement="bottomRight"
                disabled={not_usable}
              >
                <Button label="..." type="ghost" style={{ paddingLeft: "8px", paddingRight: "8px" }} size="small" />
              </Dropdown>
            </ButtonGroup>

            <Tooltip placement="bottom" title={plan_restrictions?.can_collab ? null : "Collaboration Tools are available on our Standard and Pro plans."}>
              <Button
                label="Collab"
                type="ghost"
                size="small"
                icon="CloudOutlined"
                disabled={!plan_restrictions?.can_collab}
                // loading={projects.loading}
                style={{ marginRight: "5px", marginLeft: "5px" }}
                onClick={() => open_collab(true, using_canopy ? canopy.project_id : inputs.project_id)}
              />
            </Tooltip>

            <Button
              size="small"
              label="Projects"
              type="ghost"
              icon="FolderOpenOutlined"
              disabled={using_canopy ? running_canopy : not_usable || running}
              // loading={projects.loading}
              style={{ marginRight: "5px", marginLeft: "5px" }}
              // onClick={() => open_modal(true, "load")}
              onClick={open_datamanager}
            />
          </section>

          <section className="pm-close">
            <Button type="link" size="small" onClick={() => open_modal(false, modal_visible.type)}>
              <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="https://www.w3.org/2000/svg" style={{ transform: "rotate(-180deg) scale(1.3)" }}>
                <path fillRule="evenodd" clipRule="evenodd" d="M4.73488 6L0 1.26512L1.26512 0L7.26512 6L1.26512 12L0 10.7349L4.73488 6Z" fill="var(--primary-brand-color)" />
              </svg>
            </Button>
          </section>
        </section>

        <div className="project-title">
          {/* {mode == "canopy" && carport.projectManager.currentProjectId && <p>{carport.projectManager.localProjects.local[carport.projectManager.currentProjectId].name}</p>} */}
          {/* {mode == "ground" && portal.current_project.name && <p>{portal.current_project.name}</p>} */}
          {/* {mode == "ground" && inputs.project_name && <p>{inputs.project_name}</p>} */}
          {projects.loading ? (
            <label>
              <Spin indicator={small_loading} /> Loading..
            </label>
          ) : using_canopy ? (
            <>{<label alt="Project Name">{canopy.project_name ? `${canopy.project_name}` : ""}</label>}</>
          ) : (
            <>
              {<label alt="Variant Name">{inputs.project_name ? `${inputs.project_name}` : ""}</label>}
              <br />
              {inputs.variant_name && (
                <label style={{ paddingLeft: "5px" }}>
                  <RightOutlined />
                  {inputs.variant_name}
                </label>
              )}
            </>
            // <Tooltip placement="bottom" title={inputs.project_name}>
            // </Tooltip>
          )}
          {using_canopy ? (
            <span>{canopy.project_id && projects.local[canopy.project_id]?.collab_on == 1 && <span onClick={() => open_collab(true, canopy.project_id)}>{collab_message(using_canopy)}</span>}</span>
          ) : (
            <span>{inputs.project_id && projects.local[inputs.project_id]?.collab_on == 1 && <span onClick={() => open_collab(true, inputs.project_id)}>{collab_message(using_canopy)}</span>}</span>
          )}
        </div>
      </>

      <Modal
        className="sift-modal"
        onClose={onCloseModal}
        key="name-modal"
        closable={true}
        mask={false}
        open={save_as_modal.visible}
        cancelText="Close"
        okText="Save"
        title="Save As"
        maskClosable={false}
        width={"25%"}
        onCancel={onCloseModal}
        onOk={save_as_}
        // footer={[
        //     <Button label="Save" size="small" onClick={save_as_} loading={loading} type="primary" key="save"/>
        // ]}
      >
        <div style={{ overflowX: "auto" }}>
          <div style={{ padding: "2%" }}>
            {save_as_modal.type == "saveasproject" && (
              <Input
                id="temp_project_name"
                disabled={loading}
                className="login-input"
                // ref={(input) => input && input.focus()}
                prefix={<ProjectOutlined type="text" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Enter project name"
                value={save_as_modal.temp_project_name}
                // value={project_name}
                onChange={(e) => onUpdateProjectName(e)}
                // onPressEnter={save_as_}
              />
            )}
            <div style={{ height: "10px" }}></div>
            <Input
              id="temp_variant_name"
              disabled={loading}
              className="login-input"
              // ref={(input) => input && input.focus()}
              prefix={<ProjectOutlined type="text" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Enter variant name"
              value={save_as_modal.temp_variant_name}
              // value={variant_name}
              onChange={(e) => onUpdateVariantName(e)}
              // onPressEnter={save_as_}
            />
          </div>
        </div>
      </Modal>

      <Modal
        className="sift-modal"
        closable={true}
        // afterOpenChange={(e) => {
        //   manage_modal_zIndex(e);
        // }}
        // maskClosable={true}
        maskClosable={true}
        width={modal_visible.type === "save" || modal_visible.type === "saveas" ? "25%" : "65%"}
        // visible={modal_visible.visible}
        // open={modal_visible.visible}
        open={false}
        onCancel={() => {
          open_modal(false, modal_visible.type);
        }}
        footer={
          <section style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            {modal_visible.type === "load" && (
              <span style={{ position: "absolute", left: "15px" }}>
                <Input
                  type="text"
                  value={filter_text}
                  onChange={(e) => set_filter_text(e.target.value)}
                  ref={(input) => input && input.focus()}
                  placeholder="Filter projects by name"
                  style={{ width: "200px" }}
                />
              </span>
            )}
            <Button label="Close" size="small" type="ghost" onClick={() => open_modal(false, modal_visible.type)} disabled={projects.loading} />
            {modal_visible.type === "save" && <Button label="Save" size="small" onClick={() => save_project()} loading={projects.loading} type="primary" placeholder="Enter project name." />}
            {modal_visible.type === "saveas" && <Button label="Save As" size="small" onClick={() => save_as_project()} loading={projects.loading} type="primary" placeholder="Enter project name." />}
            {modal_visible.type === "load" && <Button label="Load" size="small" onClick={() => load_project(project_id_ref.current)} loading={projects.loading} />}
          </section>
        }
      >
        <div style={{ overflowX: "auto" }}>
          {(modal_visible.type === "save" || modal_visible.type === "saveas") && (
            <div style={{ padding: "2%" }}>
              <Input
                id="temp_project_name"
                disabled={projects.loading}
                className="login-input"
                ref={(input) => input && input.focus()}
                prefix={<ProjectOutlined type="project" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Enter project name."
                value={temp_project_name}
                onChange={(e) => set_temp_project_name(e.target.value)}
                onPressEnter={() => (modal_visible.type === "save" ? save_project() : modal_visible.type === "saveas" && save_as_project())}
              />
            </div>
          )}
        </div>
      </Modal>

      {/* This modal was either launched from the project modal table -OR- through the collab buttons from within a loaded project */}
      <Modal
        // afterOpenChange={(e) => {
        //   manage_modal_zIndex(e);
        // }}
        style={{ zIndex: "auto" }}
        title={<span>Collaborate {collab_visible.project_id && `: ${projects.local[collab_visible.project_id].project_name}`}</span>}
        className="sift-modal"
        maskClosable={true}
        closable={true}
        onCancel={() => {
          open_collab(false, undefined);
        }}
        width="65%"
        // visible={collab_visible.visible}
        open={collab_visible.visible}
        footer={
          <div>
            <Button label="Close" type="ghost" size="small" onClick={() => open_collab(false, undefined)} />
          </div>
        }
      >
        <div style={{ height: "400px", overflowY: "scroll" }}>
          <Spin tip="Closing..." spinning={!collab_visible.visible} style={{ height: "100%" }}>
            {(inputs.project_id || projects.local[collab_visible.project_id]) && (
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TempEmailInput loading={projects.loading} temp_collab_email_ref={temp_collab_email_ref} share_project_with_email={share_project_with_email} />
                  <Tooltip title={can_share ? null : "Starter accounts cannot share projects."}>
                    <Button
                      label="Share"
                      type="ghost"
                      icon="UserAddOutlined"
                      style={{ marginRight: "10px" }}
                      disabled={!can_share}
                      onClick={() => share_project_with_email()}
                      loading={projects.loading}
                    />
                  </Tooltip>

                  {projects.local[collab_visible.project_id]?.collab_on === 1 && (
                    <Tooltip title="Refresh">
                      <Button type="link" size="large" icon="ReloadOutlined" style={{ marginRight: "10px" }} onClick={() => refresh_project()} loading={projects.loading} />
                    </Tooltip>
                  )}

                  {projects.local[collab_visible.project_id]?.creator && (
                    <Tooltip title="Force check in.">
                      <Button
                        type="link"
                        size="large"
                        icon="UnlockOutlined"
                        style={{ marginRight: "10px" }}
                        onClick={() => check_in_collab(true, collab_visible.project_id, false)}
                        loading={projects.loading}
                      />
                    </Tooltip>
                  )}
                </div>
                <span style={{ color: "red" }}>{collab_error}</span>
                <br />
                {/* 
									this is the checkin/checkout button
									1- you can check Out project, which locks to all other users
									2- you can check In project, which is save the changes you've made for other users to see
									3- you can't do anything because another user has it checked-out

									collab_projectId && collab_project.collab.locked_by_other
								*/}

                <section>
                  {projects.local[collab_visible.project_id]?.collab_on === 0 || projects.local[collab_visible.project_id]?.collab_on === undefined ? (
                    <section></section>
                  ) : projects.local[collab_visible.project_id]?.locked_by_me ? (
                    <section style={{ display: "flex", alignItems: "start" }}>
                      <CheckInNoteTextArea handleCheckInText={handleCheckInText} loading={projects.loading} current_collab_note_ref={current_collab_note_ref} check_in_collab={check_in_collab} />
                      <Button type="ghost" icon={<CloudUploadOutlined />} loading={projects.loading} style={{ marginRight: "10px" }} onClick={() => check_in_collab(true)}>
                        Check In
                      </Button>
                      {/* <NotifyCheckbox handleCheckbox={handleCheckbox} collab_notify_users_ref={collab_notify_users_ref.current} /> */}
                      <Checkbox style={{ marginLeft: 5 }} key="notify-check" checked={collab_notify_users} onChange={(e) => set_collab_notify_users(e.target.checked)}>
                        Notify users of Check In
                      </Checkbox>
                      {/* TOFO */}
                    </section>
                  ) : projects.local[collab_visible.project_id]?.locked ? (
                    <section>
                      {/* <Button type="ghost" icon={<LockOutlined />}
												disabled 
												style={{marginRight:'10px'}}
											> 
												Locked
											</Button> */}
                      Project is Checked Out by {projects.local[collab_visible.project_id]?.locked_by_email}. Project is Read Only.
                    </section>
                  ) : (
                    <section>
                      <Button type="ghost" icon={<CloudDownloadOutlined />} loading={projects.loading} style={{ marginRight: "10px" }} onClick={() => check_in_collab(false)}>
                        Check Out Project
                      </Button>
                    </section>
                  )}
                </section>
                <br />
                <Table
                  loading={projects.loading}
                  dataSource={projects.local[collab_visible.project_id]?.user_list}
                  useFixedHeader={false}
                  scroll={{ x: "max-content", y: 96 }}
                  className="project-table"
                  rowKey="email"
                  size="small"
                  pagination={false}
                  // onChange={this.handleSortChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {}, // click row
                      onDoubleClick: (event) => {}, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                  columns={collab_columns}
                />
                <div style={{ height: "10px" }}></div>
                <Table
                  loading={projects.loading}
                  dataSource={projects.local[collab_visible.project_id]?.notes}
                  useFixedHeader={false}
                  scroll={{ x: "max-content", y: 96 }}
                  className="project-table"
                  rowKey="email"
                  size="small"
                  pagination={false}
                  // onChange={this.handleSortChange}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {}, // click row
                      onDoubleClick: (event) => {}, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                  columns={notes_columns}
                />
                {/* Functionality on this screen:
								<ol>
									<li>See if collab is enabled for this project</li>
									<li>See if project is locked</li>
									<li>Quick share project to another user</li>

								</ol> */}
              </div>
            )}
            {!inputs.project_id && collab_visible.visible && !collab_visible.project_id && (
              <div style={{ paddingTop: "70px" }}>
                <Empty description={<span>Save to enable collaborate.</span>}>
                  <section style={{ display: "flex", justifyContent: "Center" }}>
                    <Input
                      id="project_name"
                      ref={(input) => input && input.focus()}
                      disabled={not_usable}
                      value={temp_project_name}
                      prefix={<BookOutlined type="project" style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="Enter project name."
                      onChange={(e) => set_temp_project_name(e.target.value)}
                      onPressEnter={() => save_as_project(true)}
                      style={{ width: "200px", marginRight: "10px" }}
                    />
                    <Button label="Save" type="primary" icon="SaveOutlined" loading={projects.loading} onClick={() => save_as_project(true)} />
                  </section>
                </Empty>
              </div>
            )}
          </Spin>
        </div>
      </Modal>
    </ProjectManagerWrap>
  );
};

export { ProjectManager };

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";

import { canopyActions, usePrevious, getCenterPointofFeature } from "../../../Redux";
import { build_shade_object, getCornersOfPolygon } from "../ShadeHelpers";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

const DrawBuilding = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const drawingBuilding = useSelector((state) => state.canopy.drawing_building);
  const prevDrawingBuilding = usePrevious(drawingBuilding);
  const shadeHeightUnit = useSelector((state) => state.canopy.shade_height_unit);

  const cancelKey = useKey("Escape");
  const drawingBuildingRef = useRef(drawingBuilding);

  useEffect(() => {
    drawingBuildingRef.current = drawingBuilding;
    if (!prevDrawingBuilding && drawingBuilding) {
      draw.changeMode("draw_rectangle");
    } else if (prevDrawingBuilding && !drawingBuilding) {
      cancelDraw();
    }
  }, [drawingBuilding]);

  useEffect(() => {
    if (cancelKey && drawingBuildingRef.current) {
      cancelDraw();
    }
  }, [cancelKey]);

  useEffect(() => {
    if (!map || !draw) return;

    const handleCreated = (e) => {
      if (!drawingBuildingRef.current) return;
      const geoJson = e.features[0];
      const center = getCenterPointofFeature(geoJson);
      const corners = getCornersOfPolygon(geoJson);
      const shadeObject = build_shade_object(geoJson, corners, center, shadeHeightUnit, "building");

      dispatch(canopyActions.updateShadeObject("add_shade_object", shadeObject));
      setTimeout(() => {
        draw.delete(geoJson.id);
      }, 1500);
    };

    map.on("draw.create", handleCreated);
    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  const cancelDraw = () => {
    draw.changeMode("simple_select");
    dispatch(canopyActions.update_canopy_toolbar("drawing_building", false));
  };

  return null;
};

export { DrawBuilding };

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// antd
import { Input, Button, Select, Tooltip, Switch, Radio } from "antd";
import { MenuFoldOutlined, EllipsisOutlined, LockOutlined, UserOutlined, MinusCircleOutlined, ImportOutlined } from "@ant-design/icons";

// components
import ReactDataSheet from "react-datasheet";
import { renderSheet } from "../SheetRenderer";
import { debounce } from "../helper";
import { inputsActions, canopyActions, customParseFloat } from "../../Redux";

import { pick } from "../../_Internal";

import { calculate_racking_dims, check_for_racking_errors } from "../utils";

const tech_options = ["Si-mono", "Si-poly", "CdTe"];

const module_floats = [
  "bi_bifaciality",
  "mod_width",
  "mod_height",
  "mlm_S_ref",
  "mlm_T_ref",
  "mlm_I_sc_ref",
  "mlm_V_oc_ref",
  "mlm_I_mp_ref",
  "mlm_V_mp_ref",
  "mlm_alpha_isc",
  "mlm_beta_voc_spec",
  "mlm_R_shref",
  "mlm_R_sh0",
  "mlm_R_s",
  "mlm_R_shexp",
  "mlm_T_c_fa_alpha",
  "muPmpReq",
  "mlm_n_0",
  "mlm_mu_n",
];

const ModuleInputs = () => {
  //
  const inputs = useSelector((state) => state.inputs);
  const [local_inputs, set_local_inputs] = useState(inputs);
  useEffect(() => {
    set_local_inputs(inputs);
    // create the IAM data table
    // console.log("module inputs", pick(inputs, mod_input_ids));
    handleCreateIAMData(inputs);
  }, [inputs]);

  const [iam_dt_data, set_iam_dt_data] = useState([]);

  // Platform variables
  const platform = useSelector((state) => state.user.platform);
  const internal_sift = platform == "internal_sift";
  const using_canopy = window.location.pathname == "/canopy" || window.location.pathname == "/canopy/";
  const canopy_length = useSelector((state) => Object.values(state.canopy.canopies).length);

  const account_loading = useSelector((state) => state.user.getting_user_data);
  const input_loading = useSelector((state) => state.sift.ioManager.uiState.input_loading);
  const saving = account_loading || input_loading;

  const dispatch = useDispatch();

  const updateInput = (key, value) => {
    // console.log(key, value)
    set_local_inputs({
      ...local_inputs,
      [key]: value,
    });

    let decPart = (value + "").split(".")[1];
    if (decPart && decPart.slice(-1) == "0") return;

    // do some input validation here
    let fixed_value = value;
    if (key == "mod_rating" || key == "mlm_N_series" || key == "mlm_N_parallel") {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseInt(value);
    }
    if (module_floats.findIndex((k) => k == key) >= 0) {
      if (_.isEmpty(value) || _.isUndefined(value) || _.isNaN(value)) {
        return;
      }
      fixed_value = parseFloat(value);
    }

    dispatch(inputsActions.update_input(key, fixed_value));

    if (internal_sift && using_canopy && canopy_length > 0) {
      setTimeout(() => {
        dispatch(canopyActions.applyGlobalOverride("module_input", { key, fixed_value }, true));
      }, 500);
    }
  };

  const mod_input_ids = [
    "mod_rating",
    "en_bifacial",
    "bi_bifaciality",
    "mod_width",
    "mod_height",
    "technology",
    "mlm_S_ref",
    "mlm_T_ref",
    "mlm_I_sc_ref",
    "mlm_V_oc_ref",
    "mlm_I_mp_ref",
    "mlm_V_mp_ref",
    "mlm_alpha_isc",
    "mlm_beta_voc_spec",
    "mlm_N_series",
    "mlm_N_parallel",
    "mlm_R_shref",
    "mlm_R_sh0",
    "mlm_R_s",
    "mlm_R_shexp",
    "mlm_T_c_fa_alpha",
    "muPmpReq",
    "mlm_n_0",
    "mlm_mu_n",
    "mlm_D2MuTau",
  ];

  // useEffect(() => {

  // 	if (isNumber(inputs.mod_width) && isNumber(inputs.mod_height)) {
  // 		if (inputs.rack_dims == 0) {

  // 			let required_calc_inputs = {
  // 				mod_per_string: inputs.mod_per_string,
  // 				modules_high: inputs.modules_high,
  // 				orientation: inputs.orientation,
  // 				module_gap: inputs.module_gap,
  // 				drive_gap: inputs.drive_gap,
  // 				track_mode: inputs.track_mode,
  // 				racks: inputs.racks,
  // 				mod_width: inputs.mod_width,
  // 				mod_height: inputs.mod_height,
  // 			}
  // 			let new_racks = calculate_racking_dims(required_calc_inputs);
  // 			let bulk_update = { racks: new_racks, Fshd_CollWidth: inputs.track_mode == 0 ? new_racks[0].ydim : new_racks[0].xdim}
  // 			let _racking_errors = check_for_racking_errors({
  // 				mod_per_string: inputs.mod_per_string,
  // 				racks: inputs.racks,
  // 				mod_width: inputs.mod_width,
  // 				mod_height: inputs.mod_height
  // 			});
  // 			if (_racking_errors.contains_errors) {
  // 				bulk_update = { ...bulk_update, racking_errors: _racking_errors.errors }
  // 			}
  // 			dispatch(inputsActions.update_bulk_inputs(bulk_update));
  // 		}
  // 	}

  // }, [inputs.mod_width, inputs.mod_height]);

  // ////////////////////////////////////////////
  //	IAM DATA TABLE FUNCTION
  function handleCreateIAMData(inputs) {
    var dt_data = [
      [
        { value: "Angle", readOnly: true },
        { value: "IAM", readOnly: true },
      ],
    ];
    let data = inputs;

    for (var index in data.module_iam_ang) {
      dt_data.push([
        { readOnly: false, value: data.module_iam_ang[index] },
        { readOnly: false, value: data.module_iam_eff[index] },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={index} size="small" type="primary" ghost onClick={onInsertRow}>
                  <MenuFoldOutlined />
                </Button>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={(parseInt(index) + 1) * 10} size="small" type="primary" ghost onClick={onRemoveRow}>
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
    }

    if (data.module_iam_ang && data.module_iam_ang.length == 0) {
      dt_data.splice(1, 0, [
        { readOnly: false, value: 0 },
        { readOnly: false, value: 0 },
        {
          readOnly: true,
          value: "",
          component: (
            <Tooltip placement="right" title="(Insert Below) / (Delete)" mouseEnterDelay={0.5}>
              <div style={{ width: 60 }}>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={0} size="small" type="primary" ghost onClick={this.onInsertRow}>
                  <MenuFoldOutlined />
                </Button>
                <Button style={{ marginRight: 2, borderColor: "#ffffff" }} shape="circle" id={10} size="small" type="primary" ghost onClick={this.onRemoveRow}>
                  <MinusCircleOutlined />
                </Button>
              </div>
            </Tooltip>
          ),
          forceComponent: true,
        },
      ]);
    }

    set_iam_dt_data(dt_data);
  }

  function onInsertRow(e) {
    // which index was insert clicked at?
    let index = parseInt(e.currentTarget.id);

    // add a 0/1 at that index+1
    let t_module_iam_ang = local_inputs.module_iam_ang;
    let t_module_iam_eff = local_inputs.module_iam_eff;

    t_module_iam_ang.splice(index + 1, 0, 0);
    t_module_iam_eff.splice(index + 1, 0, 1);
    // update each item into redux

    dispatch(
      inputsActions.update_bulk_inputs({
        module_iam_ang: t_module_iam_ang,
        module_iam_eff: t_module_iam_eff,
      })
    );

    if (internal_sift && using_canopy && canopy_length > 0) {
      setTimeout(() => {
        dispatch(
          canopyActions.applyGlobalOverride(
            "module_input",
            {
              key: "iam_table",
              fixed_value: {
                module_iam_ang: t_module_iam_ang,
                module_iam_eff: t_module_iam_eff,
              },
            },
            true
          )
        );
      }, 500);
    }
  }

  function onRemoveRow(e) {
    if (local_inputs.module_iam_ang.length == 1) return;

    let index = e.currentTarget.id / 10 - 1;

    let t_module_iam_ang = local_inputs.module_iam_ang;
    let t_module_iam_eff = local_inputs.module_iam_eff;

    t_module_iam_ang.splice(index, 1);
    t_module_iam_eff.splice(index, 1);

    dispatch(
      inputsActions.update_bulk_inputs({
        module_iam_ang: t_module_iam_ang,
        module_iam_eff: t_module_iam_eff,
      })
    );

    if (internal_sift && using_canopy && canopy_length > 0) {
      setTimeout(() => {
        dispatch(
          canopyActions.applyGlobalOverride(
            "module_input",
            {
              key: "iam_table",
              fixed_value: {
                module_iam_ang: t_module_iam_ang,
                module_iam_eff: t_module_iam_eff,
              },
            },
            true
          )
        );
      }, 500);
    }
  }

  function handleChanges(changes) {
    const data = iam_dt_data.map((row) => [...row]);
    changes.forEach(({ cell, row, col, value }) => {
      if (data[row] && data[row][col]) {
        data[row][col] = { ...data[row][col], value };
      }
    });
    let newModData = data.slice(1, data.length);
    let Angle = [];
    let Eff = [];
    newModData.forEach((row) => {
      let angle = parseFloat(row[0].value) == row[0].value ? parseFloat(row[0].value) : 0;
      let eff = parseFloat(row[1].value) == row[1].value ? parseFloat(row[1].value) : 0;
      Angle.push(angle);
      Eff.push(eff);
    });

    dispatch(
      inputsActions.update_bulk_inputs({
        module_iam_ang: Angle,
        module_iam_eff: Eff,
      })
    );

    if (internal_sift && using_canopy && canopy_length > 0) {
      setTimeout(() => {
        dispatch(
          canopyActions.applyGlobalOverride(
            "module_input",
            {
              key: "iam_table",
              fixed_value: {
                module_iam_ang: Angle,
                module_iam_eff: Eff,
              },
            },
            true
          )
        );
      }, 500);
    }
  }
  // ////////////////////////////////////////////

  return (
    <div className="input-content-box">
      {/* RATING && TECHNOLOGY */}
      <div className="input-row">
        <label>Module Rating</label>
        <Input
          id="mod_rating"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={local_inputs.mod_rating}
          size="small"
          disabled={saving}
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W</span>}
        />
      </div>
      {/* BIFACIAL && BIFACIALITY FACTOR */}
      <div className="input-row">
        <label>Bifacial</label>
        <Switch
          size="small"
          id="en_bifacial"
          checked={local_inputs.en_bifacial == 1}
          onChange={(checked) => {
            updateInput("en_bifacial", checked ? 1 : 0);
          }}
          disabled={saving}
        />
      </div>
      <div className="input-row">
        <label>Bifaciality Factor</label>
        <Input
          id="bi_bifaciality"
          type="number"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={local_inputs.bi_bifaciality}
          size="small"
          step="0.01"
          disabled={local_inputs.en_bifacial == 0 || saving}
        />
      </div>

      {local_inputs.simple_module == 1 && (
        <section className="input-row">
          <label>Module Dimensions</label>
          <Radio.Group value={local_inputs.mod_dims_calc} style={{ display: "flex", flexDirection: "column" }} onChange={(e) => updateInput(e.target.id, e.target.value)}>
            <Radio id="mod_dims_calc" key="Auto" value={0}>
              Auto
            </Radio>
            <Radio id="mod_dims_calc" key="Module" value={1}>
              Manual
            </Radio>
          </Radio.Group>
        </section>
      )}

      <div className="input-row">
        <label>Dimension X</label>
        <Input
          id="mod_width"
          type="number"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={local_inputs.mod_width}
          size="small"
          step="0.001"
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
          disabled={local_inputs.mod_dims_calc == 0 || saving}
        />
      </div>
      <div className="input-row">
        <label>Dimension Y</label>
        <Input
          id="mod_height"
          type="number"
          step="0.001"
          onChange={(event) => {
            updateInput(event.target.id, event.target.value);
          }}
          value={local_inputs.mod_height}
          size="small"
          suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>m</span>}
          disabled={local_inputs.mod_dims_calc == 0 || saving}
        />
      </div>
      {/* MODULE AREA*/}
      <div className="input-row">
        <label>Module Area</label>
        <p>{`${_.round(local_inputs.mod_width * local_inputs.mod_height, 2) || 0} m²`}</p>
      </div>

      {/* {internal_sift && local_inputs.is_simplified == 0 && (
        <div className="input-row">
          <label>Simple Module</label>
          <Switch
            size="small"
            id="simple_module"
            checked={local_inputs.simple_module == 1}
            onChange={(checked) => {
              updateInput("simple_module", checked ? 1 : 0);
            }}
            disabled={saving}
          />
        </div>
      )} */}

      {local_inputs.simple_module != 1 && (
        <>
          <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />

          <div className="input-row">
            <label>Technology</label>
            <Select
              value={tech_options[local_inputs.technology]}
              size="small"
              style={{ width: "100%" }}
              onChange={(value) => {
                updateInput("technology", value);
              }}
            >
              <Select.Option key="technology1" value="0">
                {" "}
                Si-mono{" "}
              </Select.Option>
              <Select.Option key="technology2" value="1">
                {" "}
                Si-poly{" "}
              </Select.Option>
              <Select.Option key="technology3" value="2">
                {" "}
                CdTe{" "}
              </Select.Option>
            </Select>
          </div>
          {/* GREF && TREF */}
          <div className="input-row">
            <label>Gref</label>
            <Input
              id="mlm_S_ref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_S_ref}
              size="small"
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>W/m²</span>}
              disabled={saving}
            />
          </div>
          <div className="input-row">
            <label>Tref</label>
            <Input
              id="mlm_T_ref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_T_ref}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>°C</span>}
            />
          </div>
          {/* ISC && VOC */}
          <div className="input-row">
            <label>Isc</label>
            <Input
              id="mlm_I_sc_ref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_I_sc_ref}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>A</span>}
            />
          </div>
          <div className="input-row">
            <label>Voc</label>
            <Input
              id="mlm_V_oc_ref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_V_oc_ref}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
            />
          </div>
          {/* IMPP && VMP */}
          <div className="input-row">
            <label>Imp</label>
            <Input
              id="mlm_I_mp_ref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_I_mp_ref}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>A</span>}
            />
          </div>
          <div className="input-row">
            <label>Vmp</label>
            <Input
              id="mlm_V_mp_ref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_V_mp_ref}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>V</span>}
            />
          </div>
          {/* TCOEF OF ISC && TCOEF OF VOC */}
          <div className="input-row">
            <label>Tcoef of Isc</label>
            <Input
              id="mlm_alpha_isc"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_alpha_isc}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mA/°C</span>}
            />
          </div>
          <div className="input-row">
            <label>Tcoef of Voc</label>
            <Input
              id="mlm_beta_voc_spec"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_beta_voc_spec}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>mV/°C</span>}
            />
          </div>
          {/* CELLS IN A SERIES */}
          <div className="input-row">
            <label>Cells in Series</label>
            <Input
              id="mlm_N_series"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_N_series}
              size="small"
              disabled={saving}
              // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>mV/°C</span>}
            />
          </div>
          {/* CELLS IN PARALLEL */}
          <div className="input-row">
            <label>Cells in Parallel</label>
            <Input
              id="mlm_N_parallel"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_N_parallel}
              size="small"
              disabled={saving}
              // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>mV/°C</span>}
            />
          </div>

          <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />

          {/* RSH && RSHUNT AT G=0 */}
          <div className="input-row">
            <label>Rsh</label>
            <Input
              id="mlm_R_shref"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_R_shref}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
            />
          </div>
          <div className="input-row">
            <label>Rshunt at G=0</label>
            <Input
              id="mlm_R_sh0"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_R_sh0}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
            />
          </div>
          {/* RS && SHUNT EXP */}
          <div className="input-row">
            <label>Rs</label>
            <Input
              id="mlm_R_s"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_R_s}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>Ω</span>}
            />
          </div>
          <div className="input-row">
            <label>Shunt Exp</label>
            <Input
              id="mlm_R_shexp"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_R_shexp}
              size="small"
              disabled={saving}
              // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
            />
          </div>

          <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />

          {/* ABSORB COEF && TCORF OF PMPP */}
          <div className="input-row">
            <label>Absorb Coef</label>
            <Input
              id="mlm_T_c_fa_alpha"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_T_c_fa_alpha}
              size="small"
              disabled={saving}
              // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
            />
          </div>
          <div className="input-row">
            <label>Tcoef of Pmpp</label>
            <Input
              id="muPmpReq"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.muPmpReq}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>%/°C</span>}
            />
          </div>
          {/* GAMMA && TCOEF OF GAMME */}
          <div className="input-row">
            <label>Gamma</label>
            <Input
              id="mlm_n_0"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_n_0}
              size="small"
              disabled={saving}
              // suffix={<span style={{ color: 'rgba(0,0,0,.45)' }}>Ω</span>}
            />
          </div>
          <div className="input-row">
            <label>Tcoef of Gamma</label>
            <Input
              id="mlm_mu_n"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_mu_n}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>1/°C</span>}
            />
          </div>
          <div className="input-row">
            <label>Recombination di^2/MuTau</label>
            <Input
              id="mlm_D2MuTau"
              type="number"
              onChange={(event) => {
                updateInput(event.target.id, event.target.value);
              }}
              value={local_inputs.mlm_D2MuTau}
              size="small"
              disabled={saving}
              suffix={<span style={{ color: "rgba(0,0,0,.45)" }}>1/V</span>}
            />
          </div>

          <div style={{ height: "7px", borderBottom: "1px solid #e5e7e7", marginBottom: "7px" }} />

          {/* IAM */}
          <div className="input-row">
            <label>IAM</label>
          </div>
          <div className="input-table" style={{ float: "left", width: 180 }}>
            <ReactDataSheet
              data={iam_dt_data}
              sheetRenderer={renderSheet}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => (cell.readOnly ? e.preventDefault() : null)}
              onCellsChanged={handleChanges}
            />
          </div>
        </>
      )}

      {/* </Spin> */}
    </div>
  );
};

export { ModuleInputs };

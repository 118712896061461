import { useEffect, useMemo } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../DrawToolProvider";
import { getAcreage, getArea, calculateAzimuths, calculatePolygonDimensions } from "../DrawToolProvider/_draw.helpers";
import { useDispatch, useSelector } from "react-redux";
import { inputsActions } from "../..";
import { usePrevious } from "../..";

const HandleFeatureUpdate = ({ feature }) => {
  const dispatch = useDispatch();
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const draw = useMapboxDraw();
  const selectedFeatureId = useSelector((state) => state.inputs.selectedFeatureId);

  // const loading = useSelector((state) => state.projects.loading);
  // const loaded = useSelector((state) => state.projects.loaded);

  // const prev_loading = usePrevious(loading);
  // const prev_loaded = usePrevious(loaded);
  // const just_loaded = loading === false && prev_loaded === undefined;

  // useEffect(() => {
  //   console.log(" cur", loaded, loading);
  //   console.log("prev", prev_loaded, prev_loading);
  // }, [loaded, loading]);

  const memoizedFeature = useMemo(() => feature, [feature]);

  useEffect(() => {
    if (!map || !draw || !memoizedFeature) return;
    // console.log("the memorized feature:", memoizedFeature);
    map.on("draw.update", handleUpdate);

    map.once("idle", () => {
      if (selectedFeatureId) {
        selectFeatureOnMap(selectedFeatureId);
      }
    });

    return () => {
      map.off("draw.update", handleUpdate);
    };
  }, [map, memoizedFeature, memoizedFeature.properties.identity]);

  const handleUpdate = (e) => {
    let geoJson;
    if (e.action && e.action == "remove_vertex") {
      geoJson = e.updatedFeature;
      // geoJson.properties["azimuths"] = calculateAzimuths(geoJson);
      // geoJson.properties["polyDims"] = calculatePolygonDimensions(geoJson);
      geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
      geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
      dispatch(inputsActions.update_site_input("edit_site_feature", geoJson));
      draw.delete(e.updatedFeature.properties.index);
    } else {
      if (e.features[0].properties.shape !== "siteFeature") return;
      // console.log("updating feature", e.features[0]);
      if (e.features[0].properties.index === feature.properties.index) {
        geoJson = e.features[0];
        // geoJson.properties["azimuths"] = calculateAzimuths(geoJson);
        // geoJson.properties["polyDims"] = calculatePolygonDimensions(geoJson);
        geoJson.properties["area"] = getArea(geoJson.geometry.coordinates);
        geoJson.properties["acreage"] = getAcreage(geoJson.geometry.coordinates);
        dispatch(inputsActions.update_site_input("edit_site_feature", geoJson));
        draw.delete(e.features[0].id);
      }

      // selectFeatureOnMap(e.features[0].id);
    }
  };

  const selectFeatureOnMap = (id) => {
    if (!id) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  return null;
};

export { HandleFeatureUpdate };

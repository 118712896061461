import React, { useState, useEffect } from "react";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";
import { useDispatch, useSelector } from "react-redux";
import { canopyActions } from "../../..";
import { useMapboxDraw } from "../../DrawToolProvider";

const HandleShadeObjectDelete = () => {
  const dispatch = useDispatch();
  const draw = useMapboxDraw();
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);

  const delete_feature_hotkey_mac = useKey("backspace");
  const delete_feature_hotkey = useKey("delete");

  useEffect(() => {
    if (!selected_shade_object_id) return;
    if (delete_feature_hotkey || delete_feature_hotkey_mac) handleDeleteShadeFromHotkey(selected_shade_object_id);
  }, [delete_feature_hotkey, delete_feature_hotkey_mac]);

  const handleDeleteShadeFromHotkey = (id) => {
    console.log("is this running?");
    if (!id) return;
    dispatch(canopyActions.updateShadeObject("delete_shade_object", id));
    draw.delete(id);
  };

  return null;
};

export { HandleShadeObjectDelete };

import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";

import { canopyActions, usePrevious, getCenterPointofFeature } from "../../../Redux";
import { build_shade_object, getCornersOfCircle } from "../ShadeHelpers";
import { useKey } from "../../../Map/components/MapEventHandler/useKey";

const DrawTree = () => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const drawingTree = useSelector((state) => state.canopy.drawing_tree);
  const prevDrawingTree = usePrevious(drawingTree);
  const shadeHeightUnit = useSelector((state) => state.canopy.shade_height_unit);

  const cancelKey = useKey("Escape");
  const drawingTreeRef = useRef(drawingTree);

  useEffect(() => {
    drawingTreeRef.current = drawingTree;
    if (!prevDrawingTree && drawingTree) {
      draw.changeMode("draw_circle");
    } else if (prevDrawingTree && !drawingTree) {
      cancelDraw();
    }
  }, [drawingTree]);

  useEffect(() => {
    if (cancelKey && drawingTreeRef.current) {
      cancelDraw();
    }
  }, [cancelKey]);

  useEffect(() => {
    if (!map || !draw) return;

    const handleCreated = (e) => {
      if (!drawingTreeRef.current) return;
      const geoJson = e.features[0];
      const center = getCenterPointofFeature(geoJson);
      const corners = getCornersOfCircle(geoJson);
      const shadeObject = build_shade_object(geoJson, corners, center, shadeHeightUnit, "tree");

      dispatch(canopyActions.updateShadeObject("add_shade_object", shadeObject));
      draw.delete(geoJson.id);
    };

    map.on("draw.create", handleCreated);
    return () => {
      map.off("draw.create", handleCreated);
    };
  }, [map, draw]);

  const cancelDraw = () => {
    draw.changeMode("simple_select");
    dispatch(canopyActions.update_canopy_toolbar("drawing_tree", false));
  };

  return null;
};

export { DrawTree };

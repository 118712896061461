import React, { useMemo, useEffect, useRef } from "react";
import { useMap } from "react-map-gl";
import { useMapboxDraw } from "../../DrawToolProvider";
import { useSelector, useDispatch } from "react-redux";
import { getCornersOfPolygon, getCornersOfCircle } from "../ShadeHelpers";
import { canopyActions, getCenterPointofFeature, usePrevious } from "../../../Redux";

const TreeEventLayer = ({ tree, interactive = true }) => {
  const { current: mapRef } = useMap();
  const map = mapRef.getMap();
  const dispatch = useDispatch();
  const draw = useMapboxDraw();

  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const selected_shade_object_id_ref = useRef(selected_shade_object_id);
  const prev_selected_shade_object_id = usePrevious(selected_shade_object_id);

  useEffect(() => {
    selected_shade_object_id_ref.current = selected_shade_object_id;
  }, [selected_shade_object_id]);

  const memoizedTree = useMemo(() => tree, [tree]);

  // useEffect(() => {
  //   if (selected_shade_object_id && prev_selected_shade_object_id !== selected_shade_object_id) {
  //     if (selected_shade_object_id === memoizedTree.id) {
  //       selectFeatureOnMap(selected_shade_object_id);
  //     }
  //   }
  // }, [selected_shade_object_id]);

  useEffect(() => {
    if (!map || !draw || !memoizedTree) return;

    const memoizedTreeWithId = {
      ...memoizedTree.geoJson,
      id: tree.id,
      properties: {
        ...memoizedTree.geoJson.properties,
        shape: "tree",
      },
    };

    draw.add(memoizedTreeWithId);

    map.once("idle", () => {
      selectFeatureOnMap(selected_shade_object_id_ref.current);
    });

    map.on("draw.update", handleUpdate);
    map.on("draw.delete", handleDelete);
    return () => {
      draw.delete(memoizedTree.id);
      map.off("draw.update", handleUpdate);
      map.off("draw.delete", handleDelete);
    };
  }, [map, memoizedTree]);

  const selectFeatureOnMap = (id) => {
    if (!id || !draw.get(id)) return;
    draw.changeMode("direct_select", { featureId: id });
  };

  const handleDelete = (e) => {
    if (e.features[0].id === tree.id) {
      dispatch(canopyActions.updateShadeObject("delete_shade_object", tree.id));
      draw.delete(e.features[0].id);
    }
  };

  const handleUpdate = (e) => {
    if (e.features[0].properties.shape === "siteFeature" || selected_shade_object_id_ref.current !== tree.id) return;
    let edited_tree = { ...tree };
    edited_tree.geoJson = e.features[0];
    edited_tree.center = getCenterPointofFeature(edited_tree.geoJson);
    edited_tree.corners = getCornersOfCircle(edited_tree.geoJson);
    edited_tree.radius = edited_tree.geoJson.properties.circleRadius;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_tree));
    draw.delete(e.features[0].id);
  };

  return null;
};

export { TreeEventLayer };

import React, { useState, useEffect } from "react";
import { useMap, useMapEvents } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { getCorners, flipGeoJson } from "../ShadeHelpers/Shade.editor";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";

import { canopyActions, usePrevious } from "../../Redux";

const BuildingEventLayer = ({ id, interactive = true }) => {
  const map = useMap();
  const dispatch = useDispatch();

  const building = useSelector((state) => state.canopy.shade_objects[id]);
  const selected_shade_object_id = useSelector((state) => state.canopy.selected_shade_object_id);
  const prev_selected_shade_object_id = usePrevious(selected_shade_object_id);

  const edit_shade = useSelector((state) => state.canopy.edit_shade);
  const move_shade = useSelector((state) => state.canopy.move_shade);
  const rotate_shade = useSelector((state) => state.canopy.rotate_shade);

  const prev_edit_shade = usePrevious(edit_shade);
  const prev_move_shade = usePrevious(move_shade);
  const prev_rotate_shade = usePrevious(rotate_shade);

  const [editing, set_editing] = useState(false);

  useEffect(() => {
    if (!prev_edit_shade && edit_shade) {
      edit_shade_object();
    }
  }, [edit_shade]);

  useEffect(() => {
    if (!prev_move_shade && move_shade) {
      move_shade_object();
    }
  }, [move_shade]);

  useEffect(() => {
    if (!prev_rotate_shade && rotate_shade) {
      rotate_shade_object();
    }
  }, [rotate_shade]);

  const edit_shade_object = () => {
    if (selected_shade_object_id != id) return;
    let layer = getLayer(selected_shade_object_id);
    if (!layer) return;
    layer.pm.enable();
  };

  const move_shade_object = () => {
    if (selected_shade_object_id != id) return;
    let layer = getLayer(selected_shade_object_id);
    if (!layer) return;
    layer.pm.enableLayerDrag();
  };

  const rotate_shade_object = () => {
    if (selected_shade_object_id != id) return;
    let layer = getLayer(selected_shade_object_id);
    if (!layer) return;
    layer.pm.enableRotate();
  };

  // useMapEvents({
  //   edit_shade_object: (e) => {
  //     let layer = getLayer(e.id);
  //     if (!layer) return;
  //     layer.pm.enable();
  //   },
  //   rotate_shade_object: (e) => {
  //     console.log("this event fired", e);
  //     let layer = getLayer(e.id);
  //     if (!layer) return;
  //     layer.pm.enableRotate();
  //   },
  //   move_shade_object: (e) => {
  //     let layer = getLayer(e.id);
  //     if (!layer) return;
  //     layer.pm.enableLayerDrag();
  //   },
  // });

  useEffect(() => {
    if ((selected_shade_object_id === undefined && prev_selected_shade_object_id == building.id) || (prev_selected_shade_object_id == building.id && selected_shade_object_id != building.id)) {
      map.eachLayer((layer) => layer.options.type === "building" && layer.options.id == building.id && layer.pm.disable());
    }

    if (selected_shade_object_id == building.id && !rotate_shade && !move_shade) {
      map.eachLayer((layer) => layer.options.type === "building" && layer.options.id == building.id && layer.pm.enable({ limitMarkersToCount: 20 }));
    }
  }, [selected_shade_object_id, prev_selected_shade_object_id]);

  useEffect(() => {
    if (!building) return;
    // console.log("building", building);

    map.eachLayer((layer) => {
      if (layer.options && layer.options.id == building.id) {
        layer.remove();
      }
    });

    if (building?.checked === false) return;

    // let building_obj = new L.Rectangle(building.geoJson.geometry.coordinates, { ...building, interactive });
    let building_obj = new L.Polygon(building.geoJson.geometry.coordinates, { ...building, interactive });

    // building_obj.setLatLngs(building.geoJson.geometry.coordinates[0]);
    // building_obj.pm._setAngle(building.rotation);

    building_obj.addTo(map);

    if (selected_shade_object_id == building.id) {
      building_obj.pm.enable({ limitMarkersToCount: 20 });
    }

    building_obj.setStyle({
      stroke: true,
      color: "var(--primary-brand-color)",
      opacity: 1.0,
      weight: 2,
      fillColor: "var(--primary-brand-color)",
    });

    // building_obj.on("click", (e) => {
    //   if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
    //   if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
    // });

    building_obj.on("click", (e) => {
      if (e.originalEvent) e.originalEvent.view.L.DomEvent.stopPropagation(e);
      if (e.nativeEvent) e.nativeEvent.view.L.DomEvent.stopPropagation(e);
      if (e.target.pm.enabled()) {
        e.target.pm.disable();
        set_editing(false);
        if (e.target.options.id == selected_shade_object_id) {
          dispatch(canopyActions.updateShadeObject("selected_shade_object_id", undefined));
        }
        return;
      } else {
        e.target.pm.enable({ limitMarkersToCount: 10 });
        dispatch(canopyActions.updateShadeObject("selected_shade_object_id", e.target.options.id));
      }
    });

    building_obj.on("pm:edit", (e) => {
      if (!e || e.source === "Rotation") return;
      update_building(e);
      e.layer.remove();
    });

    building_obj.on("pm:rotateend", (e) => {
      if (!e) return;
      update_building(e);
      e.layer.remove();
    });

    return () => {
      building_obj.off("click");
      building_obj.off("pm:edit");
    };
  }, [building, building.checked]);

  const update_building = (event) => {
    set_editing(true);
    let edited_building = { ...building };
    let new_geoJson = event.layer.toGeoJSON();
    edited_building.geoJson = flipGeoJson(new_geoJson);
    edited_building.center = event.layer.getBounds().getCenter();
    edited_building.corners = getCorners(event.layer);
    edited_building.rotation = event.angle ? event.angle : edited_building.rotation;
    dispatch(canopyActions.updateShadeObject("edit_shade_object", edited_building));
  };

  const getLayer = (id) => {
    let temp_layer = false;
    map.eachLayer((layer) => {
      if (layer.options?.type === "building" && layer.options.id == id) {
        temp_layer = layer;
      }
    });
    return temp_layer;
  };

  return <></>;
};

export { BuildingEventLayer };

import { default_canopy_inputs, toolbar_ui_state, csvFields } from "./canopy_inputs";
import { canopyConstants } from "../_constants";
import { getTodaysDate } from "../..";
import { redraw_canopy, get_a_new_name, remove_paras_from_canopy_name } from "../../_Internal";
import { feetToMeters, metersToFeet } from "../../EnvironmentalShade/ShadeHelpers";
const json2csv = require("json2csv").parse;
// check platform
import config from "config";

let run_side_effects;

if (config.platform === "internal_sift") {
  run_side_effects = require("./utils").run_internal_sift_side_effects;
} else {
  run_side_effects = require("./utils").run_side_effects;
}

// dat

let render_actions = ["name", "modY", "modX", "mod_height", "mod_width", "orientation", "planeType", "azimuth", "module", "modGap"];
let canopy_tool_actions = ["is_drawing", "is_editing", "is_dragging", "is_editing_modules", "is_rotating", "drawing_tree", "drawing_tree_line", "drawing_building"];

export function canopy(state = JSON.parse(JSON.stringify(default_canopy_inputs)), action) {
  switch (action.type) {
    case canopyConstants.UPDATE_CANOPY_INPUT:
      let updated_input_state = {};

      if (action.key === "add_canopy") {
        let temp_new_canopy = { ...JSON.parse(JSON.stringify(action.value)) };
        let temp_names = [];
        temp_new_canopy.name = remove_paras_from_canopy_name(temp_new_canopy.name);
        Object.values(state.canopies).map((canopy) => {
          if (canopy.name.includes(temp_new_canopy.name)) {
            temp_names.push(canopy.name);
          }
        });

        if (temp_names.length >= 1) {
          temp_new_canopy.name = get_a_new_name(temp_new_canopy.name, temp_names);
        }

        updated_input_state = {
          ...state,
          canopies: { ...state.canopies, [action.value.id]: { ...temp_new_canopy, changed_from_inputs: false, changed_from_override: false } },
          selected_canopy: action.value.id,
          copy_canopy_id: undefined,
          deleted_canopy_id: undefined,
          delete_shade_id: undefined,
          selected_shade_object_id: undefined,
          is_drawing: false,
          id_editing: false,
          is_dragging: false,
          is_rotating: false,
          input_changed_warning: state.canopy_results_generated,
        };
      } else if (action.key === "edit_canopy") {
        updated_input_state = {
          ...state,
          input_changed_warning: state.canopy_results_generated,
          canopies: {
            ...state.canopies,
            [action.value.id]: {
              ...action.value,
              changed_from_inputs: action.value.changed_from_inputs !== false,
              changed_from_override: false,
            },
          },
        };
      } else if (action.key === "clear_canopy_results") {
        updated_input_state = {
          ...state,
          cannopy_results: {},
          canopy_results_generated: false,
          show_canopy_results: false,
          show_canopy_report: false,
          show_percent_report: false,
          tsvResult: {},
          csvResult: {},
          total_mod_qty: 0,
        };
      } else if (
        // this is weird but sometimes this condition executes even if the action isn't found in "canopy_tool_actions", which is why the extra bangs are in here.
        // TODO: improve this so these conditions don't have to be here
        action.key !== "selected_canopy" &&
        action.key !== "show_canopy_report" &&
        action.key !== "show_percent_report" &&
        action.key !== "show_canopy_results" &&
        action.key !== "copy_canopy_id" &&
        action.key !== "canopy_results" &&
        action.key !== "report_ready" &&
        action.key !== "show_names" &&
        action.key !== "project_name" &&
        action.key !== "project_id" &&
        action.key !== "errors" &&
        action.key !== "shade_marker_pos" &&
        canopy_tool_actions.findIndex((action) => action === action.key >= 0)
      ) {
        if (!action.value) {
          updated_input_state = { ...state, ...toolbar_ui_state.default };
        } else {
          updated_input_state = { ...state, ...toolbar_ui_state[action.key] };
        }
      } else if (action.key === "selected_canopy") {
        updated_input_state = { ...state, [action.key]: action.value, selected_shade_object_id: action.value ? undefined : state.selected_shade_object_id, ...toolbar_ui_state.default };
      } else {
        updated_input_state = { ...state, [action.key]: action.value, report_ready: action.report_ready };
      }

      return { ...updated_input_state };

    case canopyConstants.UPDATE_CANOPY_BULK_INPUTS:
      let temp_bulks_state = { ...state };

      Object.keys(action.keys).map((key) => (temp_bulks_state[key] = action.keys[key]));

      return temp_bulks_state;

    case canopyConstants.UPDATE_CANOPY_PROPERTY:
      // setup a temp canopy
      let temp_canopy = state.canopies[action.canopy_id];
      temp_canopy.changed_from_inputs = action.changed_from_inputs;
      // assign the value of the canopy property
      if (action.key === "mod_height" || action.key === "mod_width") {
        temp_canopy.module[action.key] = action.value;
      } else {
        temp_canopy[action.key] = action.value;
      }

      if (render_actions.findIndex((action) => action === action.key >= 0)) {
        temp_canopy = redraw_canopy(temp_canopy, action.key);
        temp_canopy.changed_from_override = false;
      }

      // add the updated canopy into state.
      let updated_canopies = {
        ...state,
        input_changed_warning: state.canopy_results_generated,
        canopies: { ...state.canopies, [action.canopy_id]: temp_canopy },
      };
      // update state

      // console.log("temp canopy", temp_canopy);
      return { ...updated_canopies };

    case canopyConstants.UPDATE_CANOPY_TOOLBAR:
      let new_toolbar_state = toolbar_ui_state.default;

      if (action.value) {
        new_toolbar_state = toolbar_ui_state[action.key];
      }

      return { ...state, ...new_toolbar_state };

    case canopyConstants.APPLY_GLOBAL_OVERRIDE:
      let temp_canopies = { ...state.canopies };
      let currently_selected_canopy = temp_canopies[state.selected_canopy];
      let new_temp_canopy;
      let new_temp_canopies;

      Object.values(temp_canopies).map((canopy) => {
        if (action.changed_from_input_header && action.key === "module") {
          //create a temp canopy that we can manipulate

          new_temp_canopy = {
            ...canopy,
            module: canopy.module_locked ? action.selected_module : canopy.module,
          };
          new_temp_canopy = redraw_canopy(new_temp_canopy, action.key);
          // check to see if the canopy module is locked. If it's unlocked, we don't want to change it or redraw it.
          // if (new_temp_canopy.module_locked) {
          //   new_temp_canopy = redraw_canopy(new_temp_canopy, action.key);
          // }
          // add that canopy to the new canopy object
          new_temp_canopies = { ...new_temp_canopies, [new_temp_canopy.id]: new_temp_canopy };
        } else if (action.key === "module_input") {
          // update individual module inputs (mod_rating, mod_height etc.)
          if (action.selected_module.key === "iam_table") {
            new_temp_canopy = {
              ...canopy,
              module: {
                ...canopy.module,
                module_iam_ang: action.selected_module.fixed_value.module_iam_ang,
                module_iam_eff: action.selected_module.fixed_value.module_iam_eff,
              },
            };
          } else {
            new_temp_canopy = {
              ...canopy,
              module: {
                ...canopy.module,
                [action.selected_module.key]: action.selected_module.fixed_value,
              },
            };
          }

          new_temp_canopy = redraw_canopy(new_temp_canopy, action.key);
          // if (action.selected_module.key == "mod_width" || action.selected_module.key == "mod_height") {
          // }

          new_temp_canopies = { ...new_temp_canopies, [new_temp_canopy.id]: new_temp_canopy };
        } else {
          // do not change or redraw if the canopy is the one currently selected
          if (canopy.id !== currently_selected_canopy.id) {
            if (action.key === "mod_height" || action.key === "mod_width") {
              canopy.module[action.key] = currently_selected_canopy.module[action.key];
            } else if (action.key === "modXGap") {
              canopy.modXGap = currently_selected_canopy.modGap;
              canopy.modYGap = currently_selected_canopy.modGap;
              canopy.modGap = currently_selected_canopy.modGap;
            } else {
              canopy[action.key] = currently_selected_canopy[action.key];
            }
            //redraw after each canopy input has been updated
            new_temp_canopy = redraw_canopy(canopy, action.key);
            new_temp_canopy.changed_from_inputs = false;
            new_temp_canopy.changed_from_override = true;
            new_temp_canopies = { ...new_temp_canopies, [new_temp_canopy.id]: new_temp_canopy };
          } else {
            // add currently selected canopy without redrawing it
            currently_selected_canopy.changed_from_inputs = false;
            currently_selected_canopy.changed_from_override = false;
            new_temp_canopies = { ...new_temp_canopies, [currently_selected_canopy.id]: currently_selected_canopy };
          }
        }
      });

      // console.log("new canopies", new_temp_canopies);

      return { ...state, canopies: new_temp_canopies, input_changed_warning: state.canopy_results_generated };

    case canopyConstants.DELETE_CANOPY:
      let temp_canopy_for_delete;

      if (action.delete_all) {
        temp_canopy_for_delete = {};
      } else {
        temp_canopy_for_delete = { ...state.canopies };
        delete temp_canopy_for_delete[action.id];
      }

      return {
        ...state,
        selected_canopy: undefined,
        canopies: temp_canopy_for_delete,
        deleted_canopy_id: action.delete_all ? "delete_all" : action.id,
      };

    case canopyConstants.RUN_CANOPY:
      return { ...state, running_canopy: true, canopy_results: {} };

    case canopyConstants.RUN_CANOPY_COMPLETE:
      let run_results = { date: "", overview: {}, results: {}, thirtyPercentInputs: {}, images: {} };
      let run_errors = [];
      let csv_results = [];
      let total_modules = 0;
      let total_generation = 0;
      let total_capacity = 0;
      let module_wattage = 0;

      // if error exists the run ended in error
      if (action.error) {
        // run_errors = action.error.error;
      }

      // if response exists the run ended with results
      if (action.response) {
        // console.log("PROCESS RESULTS HERE", action.response);

        Object.values(action.response.output.results).map((result, index) => {
          // match the result canopy data with localcopied canopies (so we don't have to send data all over)
          let temp_canopy = state.canopies[action.response.meta.canopy_ids[index]];
          // below was just for testing
          // let canopy = statee.copiedCanopies[Object.keys(statee.copiedCanopies)[0]]
          let mod_count = temp_canopy.module_count || temp_canopy.editCellsGeoJson.filter((cell) => cell.properties.enabled).length;
          total_modules += mod_count;
          total_generation += Number(parseFloat(result.results_json.lstack["fh. Energy injected into Grid (MWh)"] * 1000));
          total_capacity += mod_count * temp_canopy.module.mod_rating;
          // total_capacity += result.capacity_kw;
          module_wattage = temp_canopy.module.mod_rating;

          let value = {
            id: temp_canopy.id,
            Key: temp_canopy.id,
            Name: temp_canopy.name,
            Module: temp_canopy.module.mod_name,
            "Canopy Type": temp_canopy.planeType,
            "Azimuth (°)": temp_canopy.azimuth,
            "Module Qty": mod_count,
            "String Count": result.results_json.report.Total_Strings,
            "Capacity (kw)": Number(parseFloat((temp_canopy.modX * temp_canopy.modY * temp_canopy.module.mod_rating) / 1000).toFixed(2)),
            "Tilt (°)": temp_canopy.tilt,
            "Generation (KWh)": Number(parseFloat(result.results_json.lstack["fh. Energy injected into Grid (MWh)"] * 1000).toFixed(2)),
            "Yield (kWh/kWp)": Number(parseFloat(result.results_json.lstack["y. Yield (kWh/kWp)"]).toFixed(2)),
            "Tilt From Azimuth": temp_canopy.planeType == "inverted" ? temp_canopy.tiltFromAzimuth : undefined,
            "Tilt To Azimuth": temp_canopy.planeType == "inverted" ? temp_canopy.tiltToAzimuth : undefined,
            "Toward Azimuth": temp_canopy.planeType == "inverted" ? temp_canopy.towardAzimuth : undefined,
            Orientation: temp_canopy.orientation,
            "Mod Dimension X Qty": temp_canopy.dimensions.modX,
            "Mod Dimension Y Qty": temp_canopy.dimensions.modY,
            "Mod Dimension X Ft": temp_canopy.dimensions.x * 3.281,
            "Mod Dimension Y Ft": temp_canopy.dimensions.y * 3.281,
            "Lead Quantity": temp_canopy.lead_quantity || "N/A",
            "Linear Feet": temp_canopy.linear_feet || "N/A",
            "MC4 Connectors": temp_canopy.mc4_connectors || "N/A",
          };
          csv_results.push(value);
          run_results.results[temp_canopy.id] = { ...value, index };
        });

        run_results.date = getTodaysDate();
        run_results.overview = {
          capacity: total_capacity,
          mod_qty: total_modules,
          project: undefined,
          rating: module_wattage,
          center: action.response.meta.center,
          loc: action.response.meta.center,
          count: Object.values(run_results.results).length,
        };
        run_results.thirtyPercentInputs = undefined;

        let copy_opts = { csvFields, delimiter: "\t", eol: "", header: true, withBOM: false };
        let csv_opts = { csvFields, delimiter: ",", eol: "", header: true, withBOM: false };

        run_results.tsvResult = json2csv(csv_results, copy_opts);
        run_results.csvResult = json2csv(csv_results, csv_opts);
      }
      // console.log("results here:", run_results);

      // console.log('Final formatted results here:', final_results);

      return {
        ...state,
        canopy_results: action.response ? run_results : {},
        errors: run_errors,
        totalModules: total_modules,
        totalGeneration: total_generation,
        total_capacity: total_capacity,
        module_wattage: module_wattage,
        show_canopy_results: !!action.response,
        canopy_results_generated: !!action.response,
        running_canopy: false,
      };

    case canopyConstants.UPDATE_IMPORTED_IMAGES:
      let temp_images = { ...JSON.parse(JSON.stringify(state.images)) };

      if (action.key === "add_image") {
        temp_images[action.value.imageId] = action.value;
      } else if (action.key === "load_bulk_images") {
        temp_images = action.value;
      } else if (action.key === "edit_image") {
        Object.keys(action.value).map((key) => {
          temp_images[action.imageId][key] = action.value[key];
        });
        // temp_images = { ...temp_images, [action.imageId]: { ...temp_images[action.imageId], corners: action.value.corners, rotation: action.value.rotation } };
      } else if (action.key === "delete_image") {
        delete temp_images[action.value];
      }
      return { ...state, selectedImageId: action.value.imageId || action.imageId, images: temp_images };

    case canopyConstants.UPDATE_SHADE_OBJECT:
      let temp_shade_objects = { ...JSON.parse(JSON.stringify(state.shade_objects)) };
      let temp_shade_res = { ...JSON.parse(JSON.stringify(state.shade_results)) };
      let temp_shade_height_unit = state.shade_height_unit;
      let temp_en_shade_impact = state.en_shade_impact;
      let temp_ui_toolbar_state = { ...toolbar_ui_state.default };
      let temp_shade_object_id = undefined;
      let temp_delete_shade_id = undefined;
      let temp_shade_month = state.shade_month;
      let temp_shade_hour = state.shade_hour;

      switch (action.key) {
        case "add_shade_object":
          temp_shade_objects = { ...temp_shade_objects, [action.value.id]: action.value };
          temp_ui_toolbar_state = { ...toolbar_ui_state.default };
          temp_shade_object_id = action.value.id;
          break;

        case "selected_shade_object_id":
          temp_shade_object_id = action.value;
          break;

        case "shade_height_unit":
          temp_shade_height_unit = action.value;
          if (action.value === "m") {
            Object.values(temp_shade_objects).map((object) => (object.height = feetToMeters(object.height)));
          } else {
            Object.values(temp_shade_objects).map((object) => (object.height = metersToFeet(object.height)));
          }
          break;

        case "en_shade_impact":
          temp_en_shade_impact = action.value;
          break;

        case "shade_month":
          temp_shade_month = action.value;
          break;

        case "shade_hour":
          temp_shade_hour = action.value;
          break;

        case "load_bulk_shade_objects":
          temp_shade_objects = action.value;
          break;

        case "edit_shade_object":
          temp_shade_objects[action.value.id] = action.value;
          temp_ui_toolbar_state = { ...toolbar_ui_state.default };
          temp_shade_object_id = action.value.id;
          break;

        case "delete_shade_object":
          delete temp_shade_objects[action.value];
          if (temp_shade_res?.shadeAreas?.[action?.value]) {
            delete temp_shade_res.shadeAreas[action.value];
          }
          temp_delete_shade_id = action.value;
          temp_en_shade_impact = Object.values(temp_shade_objects).length === 0 ? 0 : temp_en_shade_impact;
          break;

        case "clear_shade_results":
          temp_shade_res = {};
          break;

        case "delete_all_shade_objects":
          temp_shade_res = {};
          temp_shade_objects = {};
          temp_ui_toolbar_state = { ...toolbar_ui_state.default };
          temp_en_shade_impact = 0;
          break;

        default:
          break;
      }

      return {
        ...state,
        ...temp_ui_toolbar_state,
        shade_objects: temp_shade_objects,
        shade_height_unit: temp_shade_height_unit,
        en_shade_impact: temp_en_shade_impact,
        shade_month: temp_shade_month,
        shade_hour: temp_shade_hour,
        selected_shade_object_id: temp_shade_object_id,
        delete_shade_id: temp_delete_shade_id,
        shade_results: temp_shade_res,
        shade_marker_pos: action.key === "delete_shade_object" ? undefined : state.shade_marker_pos,
      };

    case canopyConstants.NEW_PROJECT:
      return { ...default_canopy_inputs };

    case canopyConstants.LOAD_CANOPY_PROJECT:
      let temp_load_state = { ...JSON.parse(JSON.stringify(state)) };

      temp_load_state.canopies = action.response.rectangles;
      temp_load_state.images = action.response.images;
      temp_load_state.project_name = action.project_meta?.project_name ? action.project_meta?.project_name : action.response.project_name;
      temp_load_state.project_id = action.project_meta?.project_id ? action.project_meta?.project_id : action.project.project_id;
      temp_load_state.shade_objects = action.response.shade_objects ? action.response.shade_objects : {};

      console.log("action in canopy", action);
      return { ...temp_load_state, ...toolbar_ui_state.default };

    case canopyConstants.UPDATE_CANOPY_RUN:
      const temp_run_state = { ...JSON.parse(JSON.stringify(state)) };

      if (action.key === "canopy_running") {
        temp_run_state.running_canopy = true;
        temp_run_state.input_changed_warning = false;
      } else if (action.key === "canopy_running_reset") {
        temp_run_state.running_canopy = false;
      }

      return {
        ...state,
        running_canopy: action.key === "canopy_running_reset" ? false : action.key === "canopy_running",
        input_changed_warning: action.key !== "canopy_running",
      };
      // return { ...temp_run_state };
      return { ...temp_run_state };

    case canopyConstants.RUN_SHADE_REQUEST:
      return { ...state, running_shade_sim: true, shade_results: {}, run_shade_job_id: action.job_id };

    case canopyConstants.RUN_SHADE_CANCEL:
      return { ...state, running_shade_sim: false, shade_results: {}, run_shade_job_id: undefined };

    case canopyConstants.RUN_SHADE_COMPLETE:
      let temp_shade_results = {};
      let temp_hour = state.shade_hour;
      if (action.errors) {
      } else {
        temp_shade_results = action.results;
        const keysArray = Object.keys(temp_shade_results?.shade_day_marks?.[state.shade_month]);
        temp_hour = parseInt(keysArray[0]);
      }

      return {
        ...state,
        running_shade_sim: false,
        shade_results: temp_shade_results,
        shade_results_id: action?.results?.shade_results_id ? action?.results?.shade_results_id : undefined,
        shade_hour: temp_hour,
      };
    default:
      return { ...state };
  }
}
